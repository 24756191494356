import { useEffect, useRef } from "react";

export default function LineChart({xAxisLabels, dataSets, className}) {
    const canvasElemRef = useRef(null);
    const chartInstance = useRef(null);

    const destroyLineChart = () => {
        if (chartInstance.current) {
            chartInstance.current.destroy();
        }
    };
    const getRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }
    const formatLineChartDataSet = (label, data) => {
        return {
            label: label,
            data: data,
            fill: true,
            borderColor: getRandomColor(),
            tension: 0.5,
        };
    };

    const renderLineChart = () => {
        if (dataSets) {
            if (window.Chart) {
                if (canvasElemRef && canvasElemRef.current) {
                    destroyLineChart();

                    let chartDataSets = [];

                    if (dataSets && Array.isArray(dataSets)) {
                        for (let i = 0; i < dataSets.length; i++) {
                            if (dataSets[i].label && dataSets[i].data) {
                                chartDataSets.push({
                                    ...formatLineChartDataSet(dataSets[i].label, dataSets[i].data),
                                });
                            }
                        }
                    }
        
                    chartInstance.current = new window.Chart(canvasElemRef.current.getContext('2d'), {
                        type: 'line',
                        data: {
                            labels: xAxisLabels,
                            datasets: chartDataSets,
                        }
                    });
                } else {
                    console.error('[LineChart -> renderLineChart()] Canvas element not initialized.');
                }
            } else {
                console.error('[LineChart -> renderLineChart()] Chart.js not loaded');
            }
        }
    };

    useEffect(() => {
        renderLineChart();

        return () => {
            destroyLineChart();
        };
    }, [dataSets]);

    return (
        <>

            <div className={"chart " + className} style={{position: "relative", height: "100%"}}>
                <canvas ref={canvasElemRef}></canvas>
            </div>

        </>
    );
}

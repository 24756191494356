import { useEffect, useRef, useState } from "react";
import { useOutletContext } from "react-router-dom";
import Table from "../../../widgets/Table";
import { objectToFormData, RequestBackendAPI } from "../../../helpers/http";
import { DownloadDataAsFile, toast, togglePreloader } from "../../../helpers/viewHelper";
import Tooltip from "../../../widgets/Tooltip";
import SelectField from "../../../widgets/Fields/SelectField";

export default function StockHistoryPage() {
    const { setPageTitle, setExtraIcons } = useOutletContext();

    const initialFilterValues = {
        'location': '',
        'product': '',
        'fromDateTime': '',
        'toDateTime': '',
    };

    const [ ddLocations, setDDLocationsList ] = useState({});
    const [ ddProductsList, setDDProductsList ] = useState({});

    const [ filters, setFilters ] = useState({...initialFilterValues});
    const [ prevFilters, setPrevFilters ] = useState({...initialFilterValues});

    const [isFiltersShowing, toggleFilterFields] = useState(false);

    const exportStockUpdatesHistory = () => {
        togglePreloader('hide');

        RequestBackendAPI({
            path: `productMaster.stockExport`,
            method: 'GET',
            data: {
                ...filters,
            },
            isJSON: false,
            responseType: 'blob',
            callback: (success, response, fileName) => {
                if (success) {
                    if (response instanceof Blob) {
                        DownloadDataAsFile(response, fileName ?? 'Stock Update History.csv');
                    } else {
                        toast('error', 'Failed to export the Stock History.');
                    }
                } else {
                    toast('error', ((response && response.message) ? response.message : 'Unable to export the Stock History.'));
                }

                togglePreloader('hide');
            }
        })
    }

    const renderStockUpdateIconColumn = (rowData, column) => {
        return (
            <>
                {
                    rowData.stock_after_update > rowData.stock_before_update ?
                    <span class="text-success">
                        <svg width="1.5em" height="1.5em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-down-left"><line x1="17" y1="7" x2="7" y2="17"></line><polyline points="17 17 7 17 7 7"></polyline></svg>
                    </span> :
                    <span class="text-danger">
                        <svg width="1.5em" height="1.5em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-up-right"><line x1="7" y1="17" x2="17" y2="7"></line><polyline points="7 7 17 7 17 17"></polyline></svg>
                    </span>
                }
            </>
        );
    };

    const columns = [
        {
            'column': 'stock_update_icon',
            'label': '',
            'align': 'center',
            'width': '6%',
            'order': false,
            'renderFn': renderStockUpdateIconColumn,
        },
        {
            'column': 'location',
            'label': 'Location',
            'width': '15%',
        },
        {
            'column': 'product',
            'label': 'Product',
            'width': '15%',
        },
        {
            'column': 'stock_before_update',
            'label': 'Stock count<br>(Before update)',
            'align': 'right',
            'width': '12%',
        },
        {
            'column': 'stock_after_update',
            'label': 'Stock count<br>(After update)',
            'align': 'right',
            'width': '12%',
        },
        {
            'column': 'created_at',
            'label': 'Transferred at',
            'width': '15%',
        },
        {
            'column': 'note',
            'label': 'Note',
            'width': '25%',
        },
    ];

    const tableRef = useRef(null);

    const setDropDownsFromResponse = (dataNeeded, responseData) => {
        let newLocations = {},
            newProducts = {};

        if (responseData && responseData.dropDowns) {
            const dropDowns = responseData.dropDowns;
            if (dropDowns.locations && typeof(dropDowns.locations) == 'object' && !(dropDowns.locations instanceof Array)) {
                newLocations = {...dropDowns.locations};
            }

            if (dropDowns.products && typeof(dropDowns.products) == 'object' && !(dropDowns.products instanceof Array)) {
                newProducts = {...dropDowns.products};
            } 
        }

        if (dataNeeded == 'initial') {
            setDDLocationsList({...newLocations});

            setFilters(filtersVal => {
                return {
                    ...filtersVal,
                    'location': 'all',
                };
            });
        }

        if (dataNeeded == 'initial' || dataNeeded == 'products') {
            setDDProductsList({...newProducts});

            if (dataNeeded == 'initial') {
                setFilters(filtersVal => {
                    return {
                        ...filtersVal,
                        'product': 'all',
                    };
                });
            }
        }
    }

    const getOrderDropDowns = (dataNeeded = 'initial', filters = {}) => {
        RequestBackendAPI({
            path: 'productMaster.history.dropDowns',
            method: 'POST',
            data: {
                'dataNeeded': dataNeeded,
                'filters': filters,
            },
            callback: (success, response) => {
                setDropDownsFromResponse(dataNeeded, success && response && response.data ? response.data : {});
            }
        });
    }

    const handleFilterChange = (e) => {
        const { name, value } = e.target;

        const newFilters = {
          ...filters,
          [name]: value,
        };

        setFilters(newFilters);

        if (value) {
            if (name === 'location') {
                getOrderDropDowns('products', {
                    'location': value,
                })
            }
        }
    };

    const onFilterToggleIconClicked = (e) => {
        e.preventDefault();

        document.activeElement.blur();

        toggleFilterFields(!isFiltersShowing);
    };

    useEffect(() => {
        setExtraIcons([
            <Tooltip type="btn" title={(isFiltersShowing ? "Hide" : "Show") + " Filters"} className="nav-link" onClick={onFilterToggleIconClicked}>
                <i className={"fas fa-filter" + (isFiltersShowing ? "-circle-xmark" : "")}></i>
            </Tooltip>,
            <Tooltip type="btn" title="Export" className="nav-link text-success" onClick={exportStockUpdatesHistory}>
                <i className="fas fa-download"></i>
            </Tooltip>
        ]);

        return () => {
            setExtraIcons([]);
        };
    }, [isFiltersShowing, filters]);

    useEffect(() => {
        if (JSON.stringify(filters) != JSON.stringify(prevFilters)) {
            if (tableRef.current) {
                tableRef.current.refreshTable();
            }
        }
    }, [filters]);

    useEffect(() => {
        setPageTitle('Stock History');

        getOrderDropDowns('initial');
    }, []);

    return (
        <>
            {
                isFiltersShowing &&
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-3 mb-4">
                                        <label htmlFor="location">Location</label>
                                        <SelectField
                                            name="location"
                                            value={filters.location}
                                            options={ddLocations}
                                            onChangeFn={handleFilterChange} />
                                    </div>

                                    <div className="col-lg-3 mb-4">
                                        <label htmlFor="product">Product</label>
                                        <SelectField
                                            name="product"
                                            value={filters.product}
                                            options={ddProductsList}
                                            onChangeFn={handleFilterChange} />
                                    </div>

                                    <div className="col-lg-3 mb-4">
                                        <label htmlFor="fromDateTime">From</label>
                                        <input
                                            type="datetime-local"
                                            name="fromDateTime"
                                            className="form-control"
                                            value={filters.fromDateTime}
                                            onChange={handleFilterChange}
                                        />
                                    </div>

                                    <div className="col-lg-3 mb-4">
                                        <label htmlFor="toDateTime">To</label>
                                        <input
                                            type="datetime-local"
                                            name="toDateTime"
                                            className="form-control"
                                            value={filters.toDateTime}
                                            onChange={handleFilterChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">

                            <Table
                                apiPath='productMaster.history.list'
                                reqMethod='POST'
                                additionalRequestParams={{
                                    'filters': {...filters},
                                }}
                                ref={tableRef}
                                dataKeyFromResponse='stockUpdates'
                                columns={columns}
                                actionColumnFn={false} />

                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

import { useEffect, useRef, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import Table from "../../../widgets/Table";
import { StockTransferLink, StockTransferScreen } from "./ProductStockTransfer";
import Tooltip from "../../../widgets/Tooltip";

export default function ProductStockList() {
    const navigate = useNavigate();

    const { setPageTitle, user, isLocationUser } = useOutletContext();

    const columns = [
        {
            'column': 'product_name',
            'label': 'Product',
            'width': '30%',
        },
        {
            'column': 'stock_count',
            'label': 'In Stock',
            'width': '20%',
            'align': 'right',
        },
        {
            'column': 'unit_price',
            'label': 'Unit Price',
            'width': '20%',
            'align': 'right',
        },
        {
            'column': 'uom',
            'label': 'UOM',
            'width': '20%',
            'align': 'left',
        },
    ];

    const tableRef = useRef(null);

    const [ isStockTfrScreenVisible, toggleStockTfrScreen ] = useState(false);
    const [ stockTfrProduct, setStockTfrProduct ] = useState(false);

    const redirectToStockUpdatesScreen = (e, rowData) => {
        e.preventDefault();

        navigate(`/products/${rowData.product_id}/stock-updates`, {
            state: {
                'productName': rowData.product_name,
                'backURL': '/products/stock',
            },
        });
    };

    const showTransferStockPopup = (e, rowData) => {
        e.preventDefault();

        document.activeElement.blur();

        setStockTfrProduct(rowData);

        toggleStockTfrScreen(true);
    };

    const freezeFromLocation = () => {
        if (isLocationUser()) {
            return user.location.id;
        }

        return false;
    };

    useEffect(() => {
        setPageTitle('Products Stock');
    }, []);

    useEffect(() => {
        if (!isStockTfrScreenVisible) {
            tableRef.current.refreshTable();
        }
    }, [isStockTfrScreenVisible]);

    return (
        <>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <Table
                                apiPath='productMaster.stock.list'
                                reqMethod='POST'
                                ref={tableRef}
                                dataKeyFromResponse='stock'
                                columns={columns}
                                actionColumnFn={(rowData) => {
                                    return (
                                        <>
                                            <Tooltip type="route" className="text-info" to={`/products/${rowData.id}/stock-updates`} onClick={e => redirectToStockUpdatesScreen(e, rowData)} title="Stock Update History">
                                                <i className="fas fa-history"></i>
                                            </Tooltip>
                                            &nbsp;|&nbsp;
                                            <StockTransferLink
                                                onClick={e => showTransferStockPopup(e, rowData)} />
                                        </>
                                    );
                                }} />

                                <StockTransferScreen
                                    productId={((stockTfrProduct && stockTfrProduct.product_id) ? stockTfrProduct.product_id : null)}
                                    isTfrScreenVisible={isStockTfrScreenVisible}
                                    toggleTfrScreen={toggleStockTfrScreen}
                                    staticFromLocationFn={freezeFromLocation} />
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

import React, { useEffect, useRef, useState} from "react";
import { RequestBackendAPI } from "../../../helpers/http";

import { Link, useOutletContext  } from "react-router-dom";
import Table from "../../../widgets/Table";
import { DownloadDataAsFile, toast, togglePreloader } from "../../../helpers/viewHelper";

import $ from 'jquery'
import SelectField from "../../../widgets/Fields/SelectField";
import Tooltip from "../../../widgets/Tooltip";

export default function SaleOrderHistoryPage() {
    const { setPageTitle, setExtraIcons } = useOutletContext();
    const columns = [
        { 'column': 'order_no', 'label': 'Order #' },
        { 'column': 'location', 'label': 'Location' },
        { 'column': 'customer', 'label': 'Customer/Vendor' },
        { 'column': 'order_date', 'label': 'Order Date' },
        { 'column': 'order_type', 'label': 'Order Type'},
        { 'column': 'status', 'label': 'Order Status'},
    ];
    const tableRef = useRef(null);

    const initialFilterValues = {
        'orderType': '',
        'location': '',
        'customer': '',
        'fromDateTime': '',
        'toDateTime': '',
    };

    const [ ddLocations, setDDLocationsList ] = useState({});
    const [ ddCustomers, setDDCustomersList ] = useState({});
    const [ ddOrderTypes, setDDOrderTypes ] = useState({});

    const [ filters, setFilters ] = useState({...initialFilterValues});
    const [ prevFilters, setPrevFilters ] = useState({...initialFilterValues});

    const [isFiltersShowing, toggleFilterFields] = useState(false);

    const [viewingOrderInfo, setViewingOrderInfo] = useState(null);
    const viewOrderModalRef = useRef(null);

    const setDropDownsFromResponse = (dataNeeded, responseData) => {
        let newOrderTypes = {},
            newLocations = {},
            newCustomers = {};

        if (responseData && responseData.dropDowns) {
            const dropDowns = responseData.dropDowns;

            if (dropDowns.orderTypes && typeof(dropDowns.orderTypes) == 'object' && !(dropDowns.orderTypes instanceof Array)) {
                newOrderTypes = {...dropDowns.orderTypes};
            }

            if (dropDowns.locations && typeof(dropDowns.locations) == 'object' && !(dropDowns.locations instanceof Array)) {
                newLocations = {...dropDowns.locations};
            }

            if (dropDowns.customers && typeof(dropDowns.customers) == 'object' && !(dropDowns.customers instanceof Array)) {
                newCustomers = {...dropDowns.customers};
            } 
        }

        if (dataNeeded == 'initial') {
            setDDOrderTypes({...newOrderTypes});
            setDDCustomersList({...newCustomers});

            setFilters(filtersVal => {
                return {
                    ...filtersVal,
                    'orderType': 'all',
                    'customer': 'all',
                };
            });
        }

        if (dataNeeded == 'initial' || dataNeeded == 'locations') {
            setDDLocationsList({...newLocations});

            if (dataNeeded == 'initial') {
                setFilters(filtersVal => {
                    return {
                        ...filtersVal,
                        'location': 'all',
                    };
                });
            }
        }
    }

    const getOrderDropDowns = (dataNeeded = 'initial', filters = {}) => {
        RequestBackendAPI({
            path: 'order.history.dropDowns',
            method: 'POST',
            data: {
                'dataNeeded': dataNeeded,
                'filters': filters,
            },
            callback: (success, response) => {
                setDropDownsFromResponse(dataNeeded, success && response && response.data ? response.data : {});
            }
        });
    }

    const handleFilterChange = (e) => {
        const { name, value } = e.target;

        const newFilters = {
          ...filters,
          [name]: value,
        };

        setFilters(newFilters);

        if (value) {
            if (name === 'orderType') {
                getOrderDropDowns('locations', {
                    'orderType': value,
                })
            }
        }
    };

    const getOrderInfo = (e, rowData) => {
        e.preventDefault();

        document.activeElement.blur();

        let infoPath = 'saleOrder.history.info';

        if (rowData.order_type == 'manufacture') {
            infoPath = 'manufactureOrder.history.info';
        } else if (rowData.order_type == 'purchase') {
            infoPath = 'purchaseOrder.history.info';
        }

        RequestBackendAPI({
            path: infoPath,
            pathReplaces: {
                'id': rowData.id,
            },
            method: 'POST',
            callback: (success, response) => {
                let newOrderInfo = null;

                if (success && response && response.data && response.data.order) {
                    newOrderInfo = {...response.data.order};
                } else {
                    let message = 'Unable to get the Order Info';

                    if (response) {
                        if (response.message) {
                            message = response.message;
                        }

                        if (response.data && response.data.message) {
                            message = response.data.message;
                        }
                    }

                    toast('error', message);
                }

                setViewingOrderInfo({
                    ...newOrderInfo,
                    'order_type': rowData.order_type,
                });
            },
        });
    };

    const onFilterToggleIconClicked = (e) => {
        e.preventDefault();

        document.activeElement.blur();

        toggleFilterFields(!isFiltersShowing);
    };

    const exportOrderHistory = () => {
        RequestBackendAPI({
            path: `order.history.all.download`,
            method: 'GET',
            data: {
                ...filters,
            },
            isJSON: false,
            responseType: 'blob',
            callback: (success, response, fileName) => {
                if (success) {
                    if (response instanceof Blob) {
                        DownloadDataAsFile(response, fileName ?? 'Order History.csv');
                    } else {
                        toast('error', 'Failed to export the Order History.');
                    }
                } else {
                    toast('error', ((response && response.message) ? response.message : 'Unable to export the Order History.'));
                }

                togglePreloader('hide');
            }
        })
    };

    useEffect(() => {
        if (viewingOrderInfo && typeof(viewingOrderInfo) !== 'undefined' && Object.keys(viewingOrderInfo).length > 0 && $('#orderInfoModal').length > 0) {
            if (!viewOrderModalRef.current) {
                viewOrderModalRef.current = new window.bootstrap.Modal(document.getElementById('orderInfoModal'), {
                    'backdrop': 'static',
                    'keyboard': false,
                });
            }

            viewOrderModalRef.current.show();
        }
    }, [viewingOrderInfo]);

    useEffect(() => {
        setExtraIcons([
            <Tooltip type="btn" title={(isFiltersShowing ? "Hide" : "Show") + " Filters"} className="nav-link" onClick={onFilterToggleIconClicked}>
                <i className={"fas fa-filter" + (isFiltersShowing ? "-circle-xmark" : "")}></i>
            </Tooltip>,
            <Tooltip type="btn" title="Export" className="nav-link text-success" onClick={exportOrderHistory}>
                <i className="fas fa-download"></i>
            </Tooltip>
        ]);

        return () => {
            setExtraIcons([]);
        };
    }, [isFiltersShowing, filters]);

    useEffect(() => {
        if (JSON.stringify(filters) != JSON.stringify(prevFilters)) {
            if (tableRef.current) {
                tableRef.current.refreshTable();
            }
        }
    }, [filters]);

    useEffect(() => {
        setPageTitle('Order History');

        getOrderDropDowns('initial');
    }, []);

    return (
        <>
            {
                isFiltersShowing &&
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-4 mb-4">
                                        <label htmlFor="orderType">Order Type</label>
                                        <SelectField
                                            name="orderType"
                                            value={filters.orderType}
                                            options={ddOrderTypes}
                                            onChangeFn={handleFilterChange} />
                                    </div>

                                    <div className="col-lg-4 mb-4">
                                        <label htmlFor="location">Location</label>
                                        <SelectField
                                            name="location"
                                            value={filters.location}
                                            options={ddLocations}
                                            onChangeFn={handleFilterChange} />
                                    </div>

                                    <div className="col-lg-4 mb-4">
                                        <label htmlFor="customer">Customer/Vendor</label>
                                        <SelectField
                                            name="customer"
                                            value={filters.customer}
                                            options={ddCustomers}
                                            onChangeFn={handleFilterChange} />
                                    </div>

                                    <div className="col-lg-4 mb-4">
                                        <label htmlFor="fromDateTime">From</label>
                                        <input
                                            type="datetime-local"
                                            name="fromDateTime"
                                            className="form-control"
                                            value={filters.fromDateTime}
                                            onChange={handleFilterChange}
                                        />
                                    </div>

                                    <div className="col-lg-4 mb-4">
                                        <label htmlFor="toDateTime">To</label>
                                        <input
                                            type="datetime-local"
                                            name="toDateTime"
                                            className="form-control"
                                            value={filters.toDateTime}
                                            onChange={handleFilterChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <Table
                                apiPath='order.history.list'
                                reqMethod='POST'
                                additionalRequestParams={{
                                    'filters': {...filters},
                                }}
                                ref={tableRef}
                                dataKeyFromResponse='orders'
                                columns={columns}
                                // paginationType='clientSide'
                                actionColumnFn={(rowData) => {
                                    return (
                                        <>
                                            {
                                                <Link className="text-primary"  href="javascript:void(0);" onClick={e => getOrderInfo(e, rowData)}>
                                                    <i className="fas fa-eye"></i>
                                                </Link>
                                            }
                                        </>
                                    );
                                }} />

                                <div class="modal fade" id="orderInfoModal" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
                                    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title">{viewingOrderInfo?.order_no ? '#' + viewingOrderInfo?.order_no : 'Sale Order'}</h5>
                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                {
                                                    viewingOrderInfo?.order_type === 'sale' &&
                                                    <SaleOrderInfo
                                                        orderInfo={viewingOrderInfo} />
                                                }

                                                {
                                                    viewingOrderInfo?.order_type === 'manufacture' &&
                                                    <ManufactureOrderInfo
                                                        orderInfo={viewingOrderInfo} />
                                                }

                                                {
                                                    viewingOrderInfo?.order_type === 'purchase' &&
                                                    <PurchaseOrderInfo
                                                        orderInfo={viewingOrderInfo} />
                                                }
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

function SaleOrderInfo({orderInfo}) {
    return (
        <>

            <div className="row">
                <div className="col">
                    <div className="form-group">
                        <label htmlFor="location">Location</label>
                        <div id="location" className="">{orderInfo?.location}</div>
                    </div>
                </div>

                <div className="col">
                    <div className="form-group">
                        <label htmlFor="customer">Customer</label>
                        <div id="customer" className="">{orderInfo?.customer}</div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div className="form-group">
                        <label htmlFor="payment_mode">Payment Mode</label>
                        <div id="payment_mode" className="">{orderInfo?.payment_mode}</div>
                    </div>
                </div>

                <div className="col">
                    <div className="form-group">
                        <label htmlFor="subtotal">Subtotal</label>
                        <div id="subtotal" className="">&#8377; {orderInfo?.subtotal}</div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div className="form-group">
                        <label htmlFor="tax_total">Tax Total</label>
                        <div id="tax_total" className="">&#8377; {orderInfo?.tax_total}</div>
                    </div>
                </div>

                <div className="col">
                    <div className="form-group">
                        <label htmlFor="grand_total">Grand Total</label>
                        <div id="grand_total" className="">&#8377; {orderInfo?.grand_total}</div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Product</th>
                                <th className="text-right">Count</th>
                                <th className="text-right">Unit Price</th>
                                <th className="text-right">Subtotal</th>
                                <th className="text-right">Tax Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                orderInfo?.order_details?.map(ordLine => {
                                    return (
                                        <tr>
                                            <td>{ordLine.product}</td>
                                            <td className="text-right">&#8377; {ordLine.product_count}</td>
                                            <td className="text-right">&#8377; {ordLine.price_unit}</td>
                                            <td className="text-right">&#8377; {ordLine.subtotal}</td>
                                            <td className="text-right">&#8377; {ordLine.tax_total}</td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>

        </>
    );
}

function ManufactureOrderInfo({orderInfo}) {
    return (
        <>

            <div className="row">
                <div className="col">
                    <div className="form-group">
                        <label htmlFor="destination_location">Manufactured Location</label>
                        <div id="destination_location" className="">{orderInfo?.destination_location}</div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div className="form-group">
                        <label htmlFor="product">Product Manufactured</label>
                        <div id="product" className="">{orderInfo?.product}</div>
                    </div>
                </div>

                <div className="col">
                    <div className="form-group">
                        <label htmlFor="product_qty">Quantity</label>
                        <div id="product_qty" className="">{orderInfo?.product_qty}</div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div className="form-group">
                        <label htmlFor="source_location">Components Location</label>
                        <div id="source_location" className="">{orderInfo?.source_location}</div>
                    </div>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col">
                    <h4>Components</h4>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Product</th>
                                <th>Count</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                orderInfo?.order_details?.map(ordLine => {
                                    return (
                                        <tr>
                                            <td>{ordLine.product}</td>
                                            <td>{ordLine.product_count}</td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>

        </>
    );
}

function PurchaseOrderInfo({orderInfo}) {
    return (
        <>

            <div className="row">
                <div className="col">
                    <div className="form-group">
                        <label htmlFor="location">Location</label>
                        <div id="location" className="">{orderInfo?.location}</div>
                    </div>
                </div>

                <div className="col">
                    <div className="form-group">
                        <label htmlFor="vendor">Vendor</label>
                        <div id="vendor" className="">{orderInfo?.vendor}</div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div className="form-group">
                        <label htmlFor="payment_mode">Payment Mode</label>
                        <div id="payment_mode" className="">{orderInfo?.payment_mode}</div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div className="form-group">
                        <label htmlFor="purchase_person">Purchased Person</label>
                        <div id="purchase_person" className="">{orderInfo?.purchase_person}</div>
                    </div>
                </div>

                <div className="col">
                    <div className="form-group">
                        <label htmlFor="purchase_datetime">Purchased Date & Time</label>
                        <div id="purchase_datetime" className="">{orderInfo?.purchase_datetime}</div>
                    </div>
                </div>
            </div>

            {
                orderInfo?.purchase_notes &&
                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <label htmlFor="purchase_notes">Purchase Notes</label>
                            <div id="purchase_notes" className="">{orderInfo?.purchase_notes}</div>
                        </div>
                    </div>
                </div>
            }

            <div className="row">
                <div className="col">
                    <div className="form-group">
                        <label htmlFor="subtotal" className="w-100">Subtotal</label>
                        <div id="subtotal" className="">&#8377; {orderInfo?.subtotal}</div>
                    </div>
                </div>

                <div className="col">
                    <div className="form-group">
                        <label htmlFor="tax_total" className="w-100">Tax Total</label>
                        <div id="tax_total" className="">&#8377; {orderInfo?.tax_total}</div>
                    </div>
                </div>

                <div className="col">
                    <div className="form-group">
                        <label htmlFor="grand_total" className="w-100">Grand Total</label>
                        <div id="grand_total" className="">&#8377; {orderInfo?.grand_total}</div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Product</th>
                                <th className="text-right">Count</th>
                                <th className="text-right">Unit Price</th>
                                <th>Tax</th>
                                <th className="text-right">Subtotal</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                orderInfo?.order_details?.map(ordLine => {
                                    return (
                                        <tr>
                                            <td>{ordLine.product}</td>
                                            <td className="text-right">{ordLine.product_count}</td>
                                            <td className="text-right">&#8377; {ordLine.price_unit}</td>
                                            <td>{ordLine.tax}</td>
                                            <td className="text-right">&#8377; {ordLine.subtotal}</td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>

        </>
    );
}

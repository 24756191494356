import { useContext, useEffect, useState } from "react";
import { Link, useOutletContext } from "react-router-dom";
import { AuthContext, AuthStates } from "../auth/authContext";
import { RequestBackendAPI } from "../helpers/http";
import { LocalDB } from "../helpers/localDB";
import { debugPrint, toast } from "../helpers/viewHelper";
import Constants from "../Constants";
import Tooltip from "../widgets/Tooltip";

function LoginPage() {
    const { setPageTitle } = useOutletContext();
    const { dispatchAppStatus } = useContext(AuthContext);

    const [ username, setUsername ] = useState('');
    const [ password, setPassword ] = useState('');

    const [ isPasswordVisible, togglePasswordVisibility ] = useState(false);
    const [ passwordIconTooltip, setPasswordIconTooltip ] = useState('Show Password');

    const [ isFormSubmitting, toggleFormSubmit ] = useState(false);

    useEffect(() => {
        setPasswordIconTooltip((isPasswordVisible ? 'Hide' : 'Show') + ' Password');
    }, [isPasswordVisible]);

    useEffect(() => {
        setPageTitle('Login');
    }, []);

    const onBtnLoginClicked = (e) => {
        e.preventDefault();

        document.activeElement.blur();

        toggleFormSubmit(true);

        try {
            if (username && username.length > 0 && password && password.length > 0) {
                RequestBackendAPI({
                    path: 'auth.login',
                    method: 'POST',
                    data: {
                        ...Constants.API_LOGIN_DEFAULT_PARAMS,
                        [Constants.API_LOGIN_FIELD_NAMES['username']]: username,
                        [Constants.API_LOGIN_FIELD_NAMES['password']]: password,
                    },
                    callback: (success, response) => {
                        let message, isSuccess = success;

                        if (response && response.message) {
                            message = response.message;
                        }

                        if (isSuccess) {
                            if (response && response.data) {
                                let userData;

                                if (Constants.API_AUTH_TYPE === 'accessToken') {
                                    if (response.data.user && response.data.authToken) {
                                        userData = {
                                            ...response.data.user,
                                        };

                                        userData['authToken'] = response.data.authToken;
                                    } else {
                                        isSuccess = false;
                                        message = null;
                                    }
                                }

                                if (Constants.API_AUTH_TYPE === 'cookie') {
                                    if (response.data.user && 'set-cookie' in response.data && response.data['set-cookie']) {
                                        userData = {
                                            'authToken': response.data['set-cookie'],
                                            ...response.data.user,
                                        };
                                    } else {
                                        isSuccess = false;
                                        message = null;
                                    }
                                }

                                if (isSuccess && userData) {
                                    LocalDB.storeJSON('user', {...userData});

                                    dispatchAppStatus({ state: AuthStates.LOGGED_IN });
                                }
                            } else {
                                isSuccess = false;
                                message = null;
                            }
                        }

                        toggleFormSubmit(false);

                        if (isSuccess) {
                            toast('success', message ?? 'Logged in successfully.');
                        } else {
                            toast('error', message ?? 'Unable to Login with the Credentials.');
                        }
                    },
                });
            } else {
                toggleFormSubmit(false);

                toast('warning', 'Please fill the Form to proceed.');
            }
        } catch(err) {
            debugPrint('[onBtnLoginClicked] Error occurred:', err);

            toast('error', 'Some error occurred when trying to Login.');

            toggleFormSubmit(false);
        }
    };

    return (
        <>
            <p className="login-box-msg">Login to proceed</p>

            <form method="POST" onSubmit={onBtnLoginClicked}>
                <div className="input-group mb-3">
                    <input type="text" className="form-control" placeholder="Username or Email Address" value={username} onChange={e => setUsername(e.target.value)} disabled={isFormSubmitting} autoFocus="on" />
                    <div className="input-group-prepend">
                        <div className="input-group-text">
                            <span className="fas fa-user"></span>
                        </div>
                    </div>
                </div>

                <div className="input-group mb-3">
                    <input type={isPasswordVisible ? "text" : "password"} className="form-control" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} disabled={isFormSubmitting} />
                    <Tooltip type="btn" className="input-group-prepend" onClick={e => togglePasswordVisibility(!isPasswordVisible)} title={passwordIconTooltip} tabIndex="-1">
                        <div className="input-group-text">
                            <span className={"fas fa-" + (isPasswordVisible ? "eye-slash" : "eye")}></span>
                        </div>
                    </Tooltip>
                </div>

                <div className="row">
                    <div className="col-12">
                        <button type="submit" className="btn btn-primary btn-block" disabled={isFormSubmitting}>
                            {
                                !isFormSubmitting &&
                                <span>Login</span>
                            }
                            {
                                isFormSubmitting &&
                                <>
                                    <i className="fas fa-circle-notch fa-spin"></i>&nbsp;<span>Please wait</span>
                                </>
                            }
                        </button>
                    </div>
                </div>
            </form>

            <p className="mt-3 mb-1">
                {/*<Link to="/auth/forgot-password">I forgot my password</Link>*/}
            </p>
        </>
    );
}

export default LoginPage;

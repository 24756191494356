import React, { useEffect, useState } from "react";
import { useOutletContext, useParams ,useNavigate, useSearchParams, useLocation} from "react-router-dom";
import { RequestBackendAPI } from "../../../helpers/http";
import { debugPrint, toast, togglePreloader } from "../../../helpers/viewHelper";
import { event, param,$ } from "jquery";
import SelectField from "../../../widgets/Fields/SelectField";
import CustomInputField from "../../../widgets/Fields/CustomInputField";

function AddManufactureOrder() {
    const { setPageTitle, user } = useOutletContext();
    const navigate = useNavigate();
    const { id, method } = useParams();
    const location = useLocation();
    const [ isViewMode, setViewMode ] = useState(false);

    useEffect(() => {
        setPageTitle((id && method ? 'View':id ? 'Edit' :'Add') + ' Orders');

        if (id) {
            getOrderInfo(id)
        } else {
            getOrderDropDowns('initial', {});
        }
    }, [id]);

    const productsInitialFormData = [{
        stock_move_id: '',
        product: '',
        product_uom_qty: '', 
    }];

    const initialFormData = {
        id: '',
        order_no:'',
        source_location: '',
        product_id: '',
        product_qty: '',
        location_dest_id: '',
    };

    const [formData, setFormData] = useState({ ...initialFormData });

    const [formDataProduct, setFormDataProduct] = useState([...productsInitialFormData]);

    const [formErrors, setFormErrors] = useState({});

    const [ ddSourceLocations, setDDSourceLocationsList ] = useState({});
    const [ ddDestinationLocations, setDDDestinationLocationsList ] = useState({});
    const [ ddMfProducts, setDDMfProductsList ] = useState({});
    const [ ddComponents, setDDComponentsList ] = useState({});

    const onOrderInfoChanged = (event) => {
        const { name, value } = event.target;
        let newFormData = {
            ...formData,
            [name]: value,
        };

        if (name === 'source_location') {
            getOrderDropDowns('components', {
                'sourceLocation': value,
                'mfProduct': newFormData.product_id,
            });
        } else if (name === 'location_dest_id') {
            getOrderDropDowns('mfProducts', {
                'destinationLocation': value,
            });
        }

        setFormData(newFormData);
    };

    const onProductFieldChanged = (event, productIndex) => {
        const { name, value } = event.target;

        let orderProductDetails = [...formDataProduct];

        orderProductDetails[productIndex][name] = value;

        if ((name == "product_qty") || (name == "products")) {
            let product_id

            if (name != "products") {
                product_id = orderProductDetails[productIndex]["products"]
            } else {
                product_id = value
            }
        }

        setFormDataProduct([...orderProductDetails]);
    }

    const shouldSrcLocationDisabled = () => {
        if (user && user.suluva_pos_user_role === 'user') {
            return Object.keys(ddSourceLocations).length === 1;
        }

        return false;
    };

    const shouldDestLocationDisabled = () => {
        if (user && user.suluva_pos_user_role === 'user') {
            return Object.keys(ddDestinationLocations).length === 1;
        }

        return false;
    };

    const setDropDownsFromResponse = (dataNeeded, responseData) => {
        let newSourceLocations = {};
        let newDestinationLocations = {};
        let newMfProducts = {};
        let newComponents = {};

        if (responseData && responseData.dropDowns) {
            const dropDowns = responseData.dropDowns;

            if (dropDowns.sourceLocations && typeof(dropDowns.sourceLocations) == 'object' && !(dropDowns.sourceLocations instanceof Array)) {
                newSourceLocations = {...dropDowns.sourceLocations};
            }
            
            if (dropDowns.destinationLocations && typeof(dropDowns.destinationLocations) == 'object' && !(dropDowns.destinationLocations instanceof Array)) {
                newDestinationLocations = {...dropDowns.destinationLocations};
            }

            if (dropDowns.mfProducts && Array.isArray(dropDowns.mfProducts)) {
                newMfProducts = {...dropDowns.mfProducts};
            }

            if (dropDowns.components && Array.isArray(dropDowns.components)) {
                newComponents = {...dropDowns.components};
            }
        }

        if (dataNeeded == 'all' || dataNeeded == 'initial') {
            setDDSourceLocationsList({...newSourceLocations});
            setDDDestinationLocationsList({...newDestinationLocations});
        }

        if (dataNeeded == 'all' || dataNeeded == 'mfProducts') {
            setDDMfProductsList({...newMfProducts});
        }

        if (dataNeeded == 'all' || dataNeeded == 'components') {
            setDDComponentsList({...newComponents});
        }

        if (dataNeeded == 'initial' && !id && user && user.suluva_pos_user_role === 'user') {
            let newSourceLoc = Object.keys(newSourceLocations).length === 1 ? Object.keys(newSourceLocations)[0] : '',
                newDestLoc = Object.keys(newDestinationLocations).length === 1 ? Object.keys(newDestinationLocations)[0] : '';

            setFormData({
                'source_location': newSourceLoc,
                'location_dest_id': newDestLoc,
            });

            getOrderDropDowns('components', {
                'sourceLocation': newSourceLoc,
            });

            getOrderDropDowns('mfProducts', {
                'destinationLocation': newDestLoc,
            });
        }
    }

    const getOrderDropDowns = (dataNeeded = 'initial', filters = {}) => {
        RequestBackendAPI({
            path: 'manufactureOrder.dropDowns',
            method: 'POST',
            data: {
                'dataNeeded': dataNeeded,
                'filters': filters,
            },
            callback: (success, response) => {
                setDropDownsFromResponse(dataNeeded, success && response && response.data ? response.data : {});
            }
        });
    }

    const onOrderFormSubmitted = (e) => {
        e.preventDefault();
        submitOrderForm();
    };

    const submitOrderForm = () => {
        let reqData = {
            ...formData,
            'order_details': [...formDataProduct],
        }

        RequestBackendAPI({
            path: 'manufactureOrder.save',
            method: 'POST',
            data: reqData,
            callback: (success, response) => {
                if (success) {

                    toast('success', response.message ?? 'order saved successfully.');
                    handleNavigateManufactureList();
                } else {
                    let errorMessage = 'Unable to save order.';

                    if (response) {
                        if (response.data && response.data.message) {
                            errorMessage = response.data.message.replace('\n', '<br>');
                        } else if (response.message) {
                            errorMessage = response.message;
                        }
                    }

                    setFormErrors(response.errors || {});
                    toast('error', errorMessage);
                }
            }
        });
    };

    // HandleEditUser
    const getOrderInfo = (orderId) => {
        setFormData('');

        togglePreloader('show');

        try {
            RequestBackendAPI({
                path: 'manufactureOrder.edit',
                pathReplaces: {
                    'id': orderId
                },
                method: 'POST',
                callback: (success, response) => {
                    if (success  && response && response.data && response.data.manufactureOrder) {

                        let orderData = {
                            ...response.data.manufactureOrder
                        };

                        // formData
                        setFormData({...orderData})

                        // prodcutsInitialFormData

                        setFormDataProduct([...(response.data.manufactureOrder.order_details ?? [])]);

                        setViewMode(orderData.order_status != 'draft');
                    } else {
                        toast('error', response.message ?? 'Unable to fetch product data');
                    }

                    setDropDownsFromResponse('all', success && response && response.data ? response.data : {});

                    togglePreloader('hide');
                }
            });
        } catch(err) {
            debugPrint('[getOrderInfo] Error occurred:', err);

            togglePreloader('hide');

            toast('error', 'Unable to fetch order data');

            navigate('/orders/manufacture-orders');
        }
    }
    // HandleEditUser

    // Add a new row
    const addRow = () => {
        setFormDataProduct([
            ...formDataProduct,
            {
                product_id: '',
            }
        ]);
    };

    // Delete a row by index
    const deleteRow = (index) => {
        setFormDataProduct(formDataProduct.filter((_, i) => i !== index));
    };

    const handleNavigateManufactureList = () => {
        navigate("/orders/manufacture-orders");
    };

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="card p-2">
                        <div className="card-header">
                            <h5>Manufacture Order</h5>
                        </div>
                        <div className="card-body">
                            <form onSubmit={onOrderFormSubmitted} disabled={isViewMode}>
                                {id &&
                                <>
                                    <div className="row">
                                        <div className="col-sm-12 col-md-6 col-lg-6">
                                            <div className="form-group">
                                                <label htmlFor="order_no">Order No</label>
                                                <input 
                                                    className="form-control order_no"
                                                    name="order_no"
                                                    value={formData.order_no}
                                                    readOnly
                                                    disabled={isViewMode}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                                }

                                <div className="row">
                                    <div className="col-sm-12 col-md-6 col-lg-6">
                                        <div className="form-group is-required-field">
                                            <label htmlFor="location_dest_id">Manufacture Location</label>
                                            <SelectField
                                                name="location_dest_id"
                                                value={formData.location_dest_id}
                                                options={ddDestinationLocations}
                                                onChangeFn={onOrderInfoChanged}
                                                className={(formErrors.location_dest_id ? 'is-invalid' : '')}
                                                disabled = {isViewMode || shouldDestLocationDisabled()} />
                                            {formErrors.location_dest_id && <div className="invalid-feedback">{formErrors.location_dest_id}</div>}
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col">
                                        <div className="form-group is-required-field">
                                            <label htmlFor="product">Product to Manufacture</label>
                                            <SelectField
                                                name="product_id"
                                                value={formData.product_id}
                                                options={ddMfProducts}
                                                onChangeFn={onOrderInfoChanged}
                                                className={(formErrors.product_id ? 'is-invalid' : '')}
                                                disabled = {isViewMode} />
                                            {formErrors.product_id && <div className="invalid-feedback">{formErrors.product_id}</div>}
                                        </div>
                                    </div>

                                    <div className="col-6">
                                        <div className="form-group is-required-field">
                                            <label htmlFor="product_qty">Quantity</label>
                                            <CustomInputField
                                                name="product_qty"
                                                value={formData.product_qty}
                                                fieldType="number"
                                                onChangeFn={onOrderInfoChanged}
                                                className={(formErrors.product_qty ? 'is-invalid' : '')}
                                                disabled={isViewMode} />
                                            {formErrors.product_qty && <div className="invalid-feedback">{formErrors.product_qty}</div>}
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-12 col-md-6 col-lg-6">
                                        <div className="form-group is-required-field">
                                            <label htmlFor="source_location">Components Location</label>
                                            <SelectField
                                                name="source_location"
                                                value={formData.source_location}
                                                options={ddSourceLocations}
                                                onChangeFn={onOrderInfoChanged}
                                                className={(formErrors.source_location ? 'is-invalid' : '')}
                                                disabled = {isViewMode || shouldSrcLocationDisabled()} />
                                            {formErrors.source_location && <div className="invalid-feedback">{formErrors.source_location}</div>}
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-12">
                                        <h2 className="d-flex align-items-center">
                                            <span>Components</span>
                                            {
                                                !isViewMode &&
                                                <a className="text-primary ml-3" href="javascript:void(0);" style={{fontSize: '0.7em'}} onClick={addRow}>
                                                    <i className="fas fa-plus-circle"></i>
                                                </a>
                                            }
                                            
                                        </h2>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th className="is-required-field" style={{ width: '80%' }}>Product</th>
                                                    <th className="is-required-field" style={{ width: '20%' }}>To Consume</th>
                                                    {
                                                        !isViewMode &&
                                                        <th>Action</th>
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    formDataProduct.length > 0 && formDataProduct.map((product, productIndex) => {
                                                        return (
                                                            <tr>
                                                                <td>
                                                                    <SelectField
                                                                        name="product"
                                                                        value={product.product}
                                                                        options={ddComponents}
                                                                        onChangeFn={(e) => {onProductFieldChanged(e, productIndex)}}
                                                                        className={(formErrors.order_details && formErrors.order_details[productIndex] && formErrors.order_details[productIndex].product ? 'is-invalid' : '')}
                                                                        disabled = {isViewMode} />
                                                                        {
                                                                            formErrors.order_details && formErrors.order_details[productIndex] && formErrors.order_details[productIndex].product &&
                                                                            <div className="invalid-feedback">{formErrors.order_details[productIndex].product}</div>
                                                                        }
                                                                    <div className="col" style={{display: 'none'}}>
                                                                        <input
                                                                            type="text"
                                                                            name="stock_move_id"
                                                                            value={product.stock_move_id}
                                                                            onChange={(e) => {onProductFieldChanged(e, productIndex)}}
                                                                            className="form-control"
                                                                            placeholder="Stock Move ID"
                                                                            disabled={isViewMode}
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <CustomInputField
                                                                        name="product_uom_qty"
                                                                        value={product.product_uom_qty}
                                                                        fieldType="number"
                                                                        onChangeFn={e => onProductFieldChanged(e, productIndex)}
                                                                        className={
                                                                            (
                                                                                formErrors.order_details && formErrors.order_details[`${productIndex}`] && formErrors.order_details[`${productIndex}`].product_uom_qty ? 'is-invalid' : ''
                                                                            )
                                                                        }
                                                                        disabled={isViewMode} />
                                                                </td>
                                                                {
                                                                    !isViewMode &&
                                                                    <td>
                                                                        <div>
                                                                            <a className="text-primary mt-5" href="javascript:void(0);" onClick={addRow}>
                                                                                <i className="fas fa-plus-circle" style={{fontSize:'1.1em'}}></i>
                                                                            </a>
                                                                            &nbsp;|&nbsp;
                                                                            <a className="text-danger" href="javascript:void(0);"  onClick={() => deleteRow(productIndex)}>
                                                                                <i className="fas fa-trash " style={{fontSize:'1.1em'}}></i>
                                                                            </a>
                                                                        </div>
                                                                    </td>
                                                                }
                                                            </tr>
                                                        );
                                                    })
                                                }
                                                {
                                                    formDataProduct.length == 0 &&
                                                    <>
                                                        <tr>
                                                            <td colSpan="6" className="text-center">
                                                                No components added for the Order. {
                                                                    !isViewMode &&
                                                                    <span>Click <a href="javascript:void(0);" onClick={addRow}>here</a> to add one</span>
                                                                }
                                                            </td>
                                                        </tr>
                                                    </>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col d-flex justify-content-end">
                                        <button type="button" className="btn btn-default" onClick={handleNavigateManufactureList}>
                                            <i className="fas fa-angles-left"></i>&nbsp;Go Back
                                        </button>
                                    </div>
                                    <div className="col">

                                    {
                                        !isViewMode &&
                                            <button type="submit" className="btn btn-primary">
                                                {formData.id ? 'Update' : 'Save'}
                                            </button>
                                     }
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddManufactureOrder;

import { useEffect, useState } from "react";

import Modal from "../../../widgets/Modal";
import Tooltip from "../../../widgets/Tooltip";
import { debugPrint, omitObjKey, toast, togglePreloader } from "../../../helpers/viewHelper";
import { RequestBackendAPI } from "../../../helpers/http";
import SelectField from "../../../widgets/Fields/SelectField";

export function StockTransferLink({onClick}) {
    return (
        <>

            <Tooltip type="btn" className="text-info" onClick={onClick} title="Stock Transfer">
                <i className="fas fa-dolly"></i>
            </Tooltip>

        </>
    );
}

export function StockTransferScreen({productId, isTfrScreenVisible, toggleTfrScreen, staticFromLocationFn}) {
    const [ isModelVisible, setModelVisibility ] = useState(false);

    const [ ddFromLocations, setDDFromLocations ] = useState({});
    const [ ddToLocations, setDDToLocations ] = useState({});

    const initialStockTfrFormData = {
        'productId': '',
        'productCurrentStock': '',
        'locationFrom': '',
        'locationTo': '',
        'stockCount': '',
    };

    const [ stockTransferFormData, setStockTransferFormData ] = useState({...initialStockTfrFormData});
    const [ stockTransferFormErrors, setStockTransferFormErrors ] = useState({});

    const onStockTfrFormFieldChanged = (e) => {
        const { name, value } = e.target;

        let newFormData = {
            ...stockTransferFormData,
            [name]: value,
        };

        if (newFormData.locationFrom == newFormData.locationTo) {
            newFormData['locationTo'] = '';
        }

        setStockTransferFormData({...newFormData});
    };

    const checkAndShowStockTfrPopup = () => {
        togglePreloader(true);

        try {
            RequestBackendAPI({
                path: 'productMaster.stockTransfer.dropDowns',
                pathReplaces: {
                    'id': productId,
                },
                method: 'POST',
                callback: (success, response) => {
                    let newDDFromLocations = {},
                        newDDToLocations = {},
                        newStockTfrFormData = {...initialStockTfrFormData},
                        errorMessage;

                    if (success && response && response.data) {
                        if (response.data.dropDowns) {
                            if (response.data.dropDowns.fromLocations && !Array.isArray(response.data.dropDowns.fromLocations)) {
                                newDDFromLocations = {...response.data.dropDowns.fromLocations};

                                if (typeof(staticFromLocationFn) === 'function') {
                                    let fromLocation = staticFromLocationFn();

                                    if (fromLocation) {
                                        newStockTfrFormData['locationFrom'] = fromLocation;
                                    }
                                }
                            }

                            if (response.data.dropDowns.toLocations && !Array.isArray(response.data.dropDowns.toLocations)) {
                                newDDToLocations = {...response.data.dropDowns.toLocations};
                            }

                            newStockTfrFormData['productId'] = productId;
                        }
                    } else {
                        if (response) {
                            if (response.data && response.data.message) {
                                errorMessage = response.data.message;
                            } else if (response.message) {
                                errorMessage = response.message;
                            }
                        }
                    }

                    if (!errorMessage) {
                        if (success) {
                            if (Object.keys(newDDFromLocations).length == 0) {
                                errorMessage = 'The product is not available in any locations.';
                            } else if (Object.keys(newDDToLocations).length == 0) {
                                errorMessage = 'Cannot get the Destination locations.';
                            }
                        } else {
                            errorMessage = 'Unable to get the Stock transfer information from Server.';
                        }
                    }

                    setDDFromLocations({...newDDFromLocations});
                    setDDToLocations({...newDDToLocations});

                    setStockTransferFormData({...newStockTfrFormData});

                    if (errorMessage) {
                        toast('error', errorMessage);
                    }

                    if (newStockTfrFormData.productId && Object.keys(newDDFromLocations).length > 0 && Object.keys(newDDToLocations).length > 0) {
                        setModelVisibility(true);
                    } else {
                        setModelVisibility(false);
                    }

                    togglePreloader(false);
                },
            });
        } catch(err) {
            debugPrint('[checkAndShowStockTfrPopup] Error occurred:', err);

            setModelVisibility(false);
            togglePreloader(false);

            toast('error', 'Error occurred when trying to get the Stock transfer information from Server.');
        }
    };

    const transferProductStock = (e) => {
        e.preventDefault();

        document.activeElement.blur();

        togglePreloader(true);

        try {
            RequestBackendAPI({
                path: 'productMaster.stockTransfer.transfer',
                pathReplaces: {
                    'id': stockTransferFormData.productId,
                },
                method: 'POST',
                data: {
                    ...stockTransferFormData,
                },
                callback: (success, response) => {
                    let newErrors = {},
                        message;

                    if (success) {
                        message = response && response.message ? response.message : 'The Product has been transferred successfully.';

                        setModelVisibility(false);
                        setStockTransferFormData({...initialStockTfrFormData});
                        setDDFromLocations({});
                        setDDToLocations({});
                    } else {
                        if (response) {
                            if (response.data && response.data.message) {
                                message = response.data.message;
                            } else if (response.message) {
                                message = response.message;
                            } else {
                                message = 'Unable to transfer the Product.';
                            }

                            if (response.errors) {
                                newErrors = {...response.errors};
                            }
                        }
                    }

                    toast(success ? 'success' : 'error', message);

                    setStockTransferFormErrors({...newErrors});

                    togglePreloader(false);
                },
            });
        } catch(err) {
            debugPrint('[transferProductStock] Error occurred:', err);

            togglePreloader(false);

            toast('error', 'Error occurred when trying to transfer the Product');
        }
    };

    useEffect(() => {
        if (isModelVisible != isTfrScreenVisible) {
            toggleTfrScreen(isModelVisible);
        }
    }, [isModelVisible]);

    useEffect(() => {
        // debugPrint('isTfrScreenVisible:', isTfrScreenVisible);
        // debugPrint('productId:', productId);

        if (productId) {
            if (isModelVisible !== isTfrScreenVisible) {
                checkAndShowStockTfrPopup();
            }
        }

    }, [productId, isTfrScreenVisible]);

    return (
        <>

            <Modal
                title={'Transfer Stock'}
                body={(
                    <>
                        <div className="row">
                            <div className="col">
                                <div className="form-group">
                                    <label htmlFor="locationFrom">Location From</label>
                                    <SelectField
                                        name="locationFrom"
                                        value={stockTransferFormData.locationFrom}
                                        options={omitObjKey(ddFromLocations, stockTransferFormData.locationTo)}
                                        onChangeFn={onStockTfrFormFieldChanged}
                                        className={(stockTransferFormErrors.locationFrom ? 'is-invalid' : '')}
                                        disabled={typeof(staticFromLocationFn) === 'function' && staticFromLocationFn()} />
                                    {stockTransferFormErrors.locationFrom && <div className="invalid-feedback">{stockTransferFormErrors.locationFrom}</div>}
                                </div>
                            </div>

                            <div className="col">
                                <div className="form-group">
                                    <label htmlFor="stockCount">No of Items to Transfer</label>
                                    <input
                                        type="text"
                                        name="stockCount"
                                        className={`form-control ${stockTransferFormErrors.code ? 'is-invalid' : ''}`}
                                        value={stockTransferFormData.stockCount}
                                        onChange={onStockTfrFormFieldChanged}
                                    />
                                    {
                                        stockTransferFormData.locationFrom && ddFromLocations &&
                                        ddFromLocations[stockTransferFormData.locationFrom] &&
                                        ddFromLocations[stockTransferFormData.locationFrom].stock &&
                                        <div className="text-hint">Max. {ddFromLocations[stockTransferFormData.locationFrom].stock}</div>
                                    }
                                    {stockTransferFormErrors.stockCount && <div className="invalid-feedback">{stockTransferFormErrors.stockCount}</div>}
                                </div>
                            </div>

                            <div className="col">
                                <div className="form-group">
                                    <label htmlFor="locationTo">Location To</label>
                                    <SelectField
                                        name="locationTo"
                                        value={stockTransferFormData.locationTo}
                                        options={omitObjKey(ddToLocations, stockTransferFormData.locationFrom)}
                                        onChangeFn={onStockTfrFormFieldChanged}
                                        className={(stockTransferFormErrors.locationTo ? 'is-invalid' : '')}  />
                                    {stockTransferFormErrors.locationTo && <div className="invalid-feedback">{stockTransferFormErrors.locationTo}</div>}
                                </div>
                            </div>
                        </div>
                    </>
                )}
                footer={(
                    <>
                        <button className="btn btn-primary" onClick={transferProductStock}><i className="fas fa-dolly"></i>&nbsp;Transfer</button>
                    </>
                )}
                show={isModelVisible}
                toggleModalVisibility={setModelVisibility}
                isScrollable={false} />

        </>
    );
}

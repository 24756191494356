import React, { useEffect, useState } from "react";
import { useOutletContext, useParams ,useNavigate} from "react-router-dom";
import { RequestBackendAPI } from "../../../helpers/http";
import { toast , togglePreloader} from "../../../helpers/viewHelper";
import SelectField from "../../../widgets/Fields/SelectField";

function AddLocation() {
    const { setPageTitle } = useOutletContext();
    const navigate = useNavigate()
    const { id } = useParams();

    useEffect(() => {
        setPageTitle((id ? 'Edit' : 'Add') + ' Location');

        if(id){
            getLocationInfo(id)
        } else {
            getLocationTypeInfo();
        }
    }, [id]);

    const initialFormData = {
        id: '',
        code: '',
        name: '',
        description: '',
        type: '',
        related_locations: [],
        canEditLocationType: true,
    };

    const [formData, setFormData] = useState({ ...initialFormData });
    const [formErrors, setFormErrors] = useState({});
    const [editMode, setEditMode] = useState(false);

    const [locationTypes, setLocationTypes] = useState({});
    const [selectedLocationType, setSelectedLocationType] = useState('');

    const [ ddLocations, setDDLocations ] = useState({});

    useEffect(() => {
        getLocationTypeInfo('locations', {
            'locationType': selectedLocationType,
            'ignoreId': formData.id,
        });
    }, [selectedLocationType]);

    function handleInputChange(event) {
        const { name, value } = event.target;

        if (name === 'type') {
            getLocationTypeInfo('locations', {
                'locationType': value,
                'ignoreId': formData.id,
            });
        }

        setFormData({
            ...formData,
            [name]: value
        });
    }

    const handleNavigateLocationList = () => {
        navigate("/admin/locations");
    };

    const renderDropDownsFromResponse = (responseData, dataNeeded = 'initial') => {
        let newLocationTypes = {},
            newDDLocations = {};

        if (responseData && responseData.dropDowns) {
            const dropDowns = responseData.dropDowns;

            if (dropDowns.locationTypes && typeof(dropDowns.locationTypes) == 'object' && !(dropDowns.locationTypes instanceof Array)) {
                newLocationTypes = {...dropDowns.locationTypes};
            }

            if (dropDowns.locations && typeof(dropDowns.locations) == 'object' && !(dropDowns.locations instanceof Array)) {
                newDDLocations = {...dropDowns.locations};
            }
        }

        if (dataNeeded == 'all' || dataNeeded == 'initial') {
            setLocationTypes({...newLocationTypes});
        }

        if (dataNeeded == 'all' || dataNeeded == 'locations') {
            setDDLocations({ ...newDDLocations });
        }
    };

    const getLocationTypeInfo = (dataNeeded = 'initial', filters = {}) => {
        RequestBackendAPI({
            path: `location.dropDown`,
            method: 'POST',
            data: {
                'dataNeeded': dataNeeded,
                'filters': {...filters},
            },
            callback: (success, response) => {
                renderDropDownsFromResponse(success && response && response.data ? response.data : {}, dataNeeded);
            }
        });
    };

    const handleAddLocation = (e) => {
        e.preventDefault();
        submitLocationForm(formData);
    };

    const submitLocationForm = (submitData) => {
        let submitFormData = {
            ...submitData,
            ...JSON.parse(JSON.stringify(submitData)),
            type: selectedLocationType !== undefined ? selectedLocationType : '',
        };

        // "Code" field is readonly
        if (submitFormData.id) {
            delete submitFormData['code'];
        }

        togglePreloader('show');
        RequestBackendAPI({
            path: 'location.save',
            method: 'POST',
            data: submitFormData,
            callback: (success, response) => {
                if (success) {
                    setFormData({ ...initialFormData });
                    toast('success', response.message ?? 'location add successfully.');
                    handleNavigateLocationList()
                    setFormErrors({});
                    if (response.data) {
                        setEditMode(false);
                    }
                    togglePreloader('hide');
                } else {
                    setFormErrors(response.errors || {});
                    toast('error', response.message ?? 'Unable to add location');
                    togglePreloader('hide');
                }
            }
        });
    };

    // getLocationInfo
    const getLocationInfo = (locationId) => {
        setFormData('');

        RequestBackendAPI({
            path: 'location.edit',
            pathReplaces: {
                'id': locationId,
            },
            method: 'POST',
            callback: (success, response) => {
                if (success) {
                    let LocationData = {
                        ...response.data.location
                    };

                    renderDropDownsFromResponse(success && response && response.data ? response.data : {}, 'all');

                    setSelectedLocationType(LocationData.type);
                    setFormData({...LocationData});
                    setEditMode(true);
                }else{
                    toast('error', response.message ?? 'Unable to fetch location data');
                }
            }
        });
    }
    // getLocationInfo
    
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="card p-2">
                        <div className="card-body">
                            <form onSubmit={handleAddLocation}>
                                <div class="row">
                                    <div class="col">
                                        <div className="form-group mb-3 is-required-field">
                                            <label htmlFor="code">Code</label>
                                            <input
                                                type="text"
                                                name="code"
                                                className={`form-control ${formErrors.code ? 'is-invalid' : ''}`}
                                                value={formData.code}
                                                readOnly={formData.id}
                                                onChange={handleInputChange}
                                                {...(formData.id ? {} : {autoFocus: true})}
                                            />
                                            {formErrors.code && <div className="invalid-feedback">{formErrors.code}</div>}
                                        </div>
                                    </div>

                                    <div className="col">
                                        <div className="form-group mb-3 is-required-field">
                                            <label htmlFor="name">Name</label>
                                            <input
                                                type="text"
                                                name="name"
                                                className={`form-control ${formErrors.name ? 'is-invalid' : ''}`}
                                                value={formData.name}
                                                onChange={handleInputChange}
                                                {...(formData.id ? {autoFocus: true} : {})}
                                            />
                                            {formErrors.name && <div className="invalid-feedback">{formErrors.name}</div>}
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col">
                                        <div className="form-group mb-3">
                                            <label htmlFor="description">Description</label>
                                            <input
                                                type="text"
                                                name="description"
                                                className={`form-control ${formErrors.description ? 'is-invalid' : ''}`}
                                                value={formData.description}
                                                onChange={handleInputChange}
                                            />
                                            {formErrors.description && <div className="invalid-feedback">{formErrors.description}</div>}
                                        </div>
                                    </div>


                                    <div className="col">
                                        <div className="form-group is-required-field">
                                            <label htmlFor="type">Location Type</label>
                                            <SelectField
                                                name="type"
                                                value={selectedLocationType}
                                                label="Location Type"
                                                options={locationTypes}
                                                onChangeFn={(e) => {setSelectedLocationType(e.target.value)}}
                                                className={(formErrors.type ? 'is-invalid' : '')}
                                                disabled={!formData.canEditLocationType} />
                                            {formErrors.type && <div className="invalid-feedback">{formErrors.type}</div>}
                                        </div>
                                    </div>
                                </div>

                                {
                                    ddLocations && Object.keys(ddLocations).length > 0 &&
                                    <div className="row">
                                        <div className="col-sm-12 col-md-6 col-lg-6">
                                            <div className="form-group">
                                                <label htmlFor="related_locations">Related Locations</label>
                                                <SelectField
                                                    name="related_locations"
                                                    value={formData.related_locations}
                                                    options={ddLocations}
                                                    onChangeFn={handleInputChange}
                                                    className={(formErrors.related_locations ? 'is-invalid' : '')}
                                                    isMulti={true}
                                                    closeMenuOnSelect={false} />
                                                {formErrors.related_locations && <div className="invalid-feedback">{formErrors.related_locations}</div>}
                                            </div>
                                        </div>
                                    </div>
                                }

                                <div className="row">
                                    <div className="col d-flex justify-content-end">
                                        <button type="button" className="btn btn-default" onClick={handleNavigateLocationList}>
                                            <i className="fas fa-angles-left"></i>&nbsp;Go Back
                                        </button>
                                    </div>

                                    <div className="col">
                                        <button type="submit" className="btn btn-primary">
                                            {formData.id ? 'Update' : 'Save'}
                                        </button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddLocation;

import React, { useEffect, useState, useRef } from "react";
import { useOutletContext } from "react-router-dom";
import { RequestBackendAPI } from "../helpers/http";
import LineChart from "../widgets/Charts/LineChart";
import { debugPrint } from "../helpers/viewHelper";

function Dashboard() {
    const { setPageTitle, setExtraIcons } = useOutletContext();

    const [infoBoxes, setInfoBoxes] = useState({
        soldOrdersAmount: 0,
        purchasedAmount: 0,
        profitAmount: 0,
        totalOutlets: 0,
    });

    const initialChartData = {
        'title': '',
        'icon': '',
        'labels': [],
        'dataSets': [],
    };
    const [saleCharts, setSaleCharts] = useState([]);

    const [ filterDate, setFilterDate ] = useState();

    const getDashboardChartsData = () => {
        RequestBackendAPI({
            path: '/admin/dashboard/charts-data',
            method: 'POST',
            data: {
                'date': filterDate,
            },
            callback: (success, response) => {
                let newInfoBoxes = {},
                    newCharts = [];

                if (success && response.success && response.data) {
                    const responseData = response.data;

                    if (responseData.summary && typeof(responseData.summary) === 'object' && !Array.isArray(responseData.summary)) {
                        newInfoBoxes = responseData.summary;
                    }

                    if (responseData.saleOrders && Array.isArray(responseData.saleOrders)) {
                        for (let i = 0; i < responseData.saleOrders.length; i++) {
                            if (responseData.saleOrders[i].labels && responseData.saleOrders[i].dataSets) {
                                newCharts.push({
                                    ...initialChartData,
                                    'title': responseData.saleOrders[i].title,
                                    'labels': responseData.saleOrders[i].labels,
                                    'dataSets': responseData.saleOrders[i].dataSets,
                                });
                            }
                        }
                    }
                }

                setInfoBoxes({...newInfoBoxes});
                setSaleCharts([...newCharts]);
                debugPrint(saleCharts)
            }
        });
    };

    const onDateFieldChanged = (e) => {
        setFilterDate(e.target.value);
    };

    useEffect(() => {
        setExtraIcons([
            <input type="date" className="form-control" name="date" onChange={onDateFieldChanged} value={filterDate} max={new Date().toISOString().split('T')[0]} />
        ]);

        getDashboardChartsData();
    }, [filterDate]);

    useEffect(() => {
        setPageTitle('Dashboard');

        let now = new Date();
        let year = now.getFullYear(),
            month = now.getMonth() + 1,
            date = now.getDate();
        let today = `${year}-`;

        if (month < 10) {
            today += `0`;
        }

        today += `${month}-`;

        if (date < 10) {
            today += `0`;
        }

        today += `${date}`;

        debugPrint('today:', today);

        setFilterDate(today);

        return () => {
            setExtraIcons([]);
        };
    }, []);

    return (
        <>
            <div className="row">
                {/* Your existing info boxes */}
                <div className="col-lg-3 col-6">
                    <div className="small-box bg-info">
                        <div className="inner">
                            <h3>&#8377; {infoBoxes.soldOrdersAmount ?? '_'}</h3>
                            <p>Sold Orders</p>
                        </div>
                        <div className="icon">
                            <i className="fas fa-hand-holding-dollar"></i>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-6">
                    <div className="small-box bg-danger">
                        <div className="inner">
                            <h3>&#8377; {infoBoxes.purchasedAmount ?? '_'}</h3>
                            <p>Purchased</p>
                        </div>
                        <div className="icon">
                            <i className="fas fa-receipt"></i>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-6">
                    <div className="small-box bg-success">
                        <div className="inner">
                            <h3>&#8377; {infoBoxes.profitAmount ?? '_'}</h3>
                            <p>Profit</p>
                        </div>
                        <div className="icon">
                            <i className="fas fa-money-bill-trend-up"></i>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-6">
                    <div className="small-box bg-warning">
                        <div className="inner">
                            <h3>{infoBoxes.totalOutlets ?? '_'}</h3>
                            <p>Shops</p>
                        </div>
                        <div className="icon">
                            <i className="fas fa-store"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                {
                    saleCharts.map((chart) => {
                        return (
                            <section className="col-lg-6">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">
                                            <i className={"fas fa-" + (chart.icon ? chart.icon : "chart-pie") + " mr-1"}></i>
                                            {chart.title}
                                        </h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="tab-content p-0">
                                            {
                                                chart.dataSets.length > 0 && chart.dataSets[0].data.length > 0 ?
                                                <>
                                                    <LineChart
                                                        xAxisLabels={chart.labels}
                                                        dataSets={chart.dataSets}
                                                        className="tab-pane active" />
                                                </>
                                                :
                                                <div className="no-data-placeholder">No Data found</div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </section>
                        );
                    })
                }
            </div>
        </>
    );
}

export default Dashboard;

import React, { useEffect, useRef, useState } from "react";
import { RequestBackendAPI } from "../../../helpers/http";

import { Link, useOutletContext ,useParams } from "react-router-dom";
import { debugPrint, toast } from "../../../helpers/viewHelper";
import Pagination from "../../../widgets/Pagination";
import Table from "../../../widgets/Table";
import { confirmDialog , togglePreloader} from "../../../helpers/viewHelper";
import Tooltip from "../../../widgets/Tooltip";
import Modal from "../../../widgets/Modal";

function UsersList() {
    const { setPageTitle } = useOutletContext();
    const columns = [
        {
            'column': 'username',
            'label': 'Username',
        },
        {
            'column': 'full_name',
            'label': 'Full Name',
        },
        {
            'column': 'email',
            'label': 'Email',
        },
        {
            'column': 'user_role',
            'label': 'Role',
        },
        {
            'column': 'user_location_type',
            'label': 'Location Type',
        },
        {
            'column': 'location',
            'label': 'Location',
        },
    ];

    const tableRef = useRef(null);

    const initialChangePwdFormData = {
        'user': '',
        'newPassword': '',
        'isNewPasswordVisible': '',
    };

    const [ isChangePwdPopupVisible, toggleChangePwdPopup ] = useState(false);
    const [ changePwdFormData, setChangePwdFormData ] = useState({...initialChangePwdFormData});
    const [ changePwdFormErrors, setChangePwdFormErrors ] = useState({});

    useEffect(() => {
        setPageTitle('Users');
    }, []);
    

    const handleUserDelete = (user) => {
        let id = user.id;

        togglePreloader('show');
        RequestBackendAPI({
            path: 'usersMaster.delete',
            pathReplaces: {
                'id': id,
            },
            method:'POST',
            callback:(success,response) => {
                if(success){
                    if (tableRef.current) {
                        tableRef.current.refreshTable();
                    }

                    toast('success', response.message ?? 'User Delete successfully.');
                    togglePreloader('hide');
                }else{
                    toast('error', response && response.message ? response.message : 'Unable to User Delete');
                    togglePreloader('hide');
                }
            }
        })
    }

    const userDelateConfirmation = (e, user) => {
        e.preventDefault();

        e.target.blur();

        confirmDialog({
            message:'Are you sure you want to delete this user',
            confirmBtn:{
                onClick:() => {
                    handleUserDelete(user)
                }
            },
            cancelBtn:{
                onClick:() => {

                }
            }
        })
    }

    const changeUserPassword = (e, user) => {
        e.preventDefault();

        document.activeElement.blur();

        debugPrint('changeUserPassword:', user);

        setChangePwdFormData({
            ...initialChangePwdFormData,
            'user': user,
        });

        setChangePwdFormErrors({});

        toggleChangePwdPopup(true);
    };

    const storeChangePwdFormData = (e) => {
        if (e.target) {
            const { name, value } = e.target;

            setChangePwdFormData({
                ...changePwdFormData,
                [name]: value,
            })
        }
    }

    const onChangePwdFormSubmitted = (e) => {
        e.preventDefault();

        document.activeElement.blur();

        try {
            togglePreloader('show');

            if (!changePwdFormData.newPassword) {
                setChangePwdFormErrors({...changePwdFormErrors, 'newPassword': 'Required.'});

                togglePreloader('hide');
                return;
            }

            RequestBackendAPI({
                path: 'usersMaster.changePassword',
                pathReplaces: {
                    'id': changePwdFormData.user.id,
                },
                method: 'POST',
                data: {
                    'newPassword': changePwdFormData.newPassword,
                },
                callback: (success, response) => {
                    if (success) {
                        toast('success', response && response.message ? response.message : 'The password has been changed successfully.');

                        toggleChangePwdPopup(false);

                        setChangePwdFormData({
                            ...initialChangePwdFormData,
                        });
                
                        setChangePwdFormErrors({});
                    } else {
                        let message = 'Unable to change the password.';

                        if (response) {
                            if (response.message) {
                                message = response.message;
                            }

                            if (response.errors) {
                                setChangePwdFormErrors({...response.errors})
                            }
                        }

                        toast('error', message);
                    }

                    togglePreloader('hide');
                },
            });
        } catch(err) {
            debugPrint('[onChangePwdFormSubmitted] Error occurred:', err);

            togglePreloader('hide');
        }
    }

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-4 mb-3">
                                    <Link to={'/admin/users/add'} className="btn btn-primary">
                                        <i className="fas fa-plus-circle"></i> Add User
                                    </Link>
                                </div>
                            </div>

                            <Table
                                apiPath='usersMaster.list'
                                reqMethod='POST'
                                ref={tableRef}
                                dataKeyFromResponse='user'
                                columns={columns}
                                actionColumnFn={(rowData) => {
                                    return (
                                        <>
                                            <Tooltip type="route" className="text-primary" to={`/admin/users/edit/${rowData.id}`} title="Edit User">
                                                <i className="fas fa-pencil"></i>
                                            </Tooltip>
                                            &nbsp;|&nbsp;
                                            <Tooltip type="btn" className="text-info" onClick={(e) => changeUserPassword(e, rowData)} title="Change Password">
                                                <i className="fas fa-unlock-keyhole"></i>
                                            </Tooltip>
                                            &nbsp;|&nbsp;
                                            <Tooltip type="btn" className="text-danger" onClick={(e) => userDelateConfirmation(e, rowData)} title="Delete User">
                                                <i className="fas fa-trash"></i>
                                            </Tooltip>
                                        </>
                                    );
                                }} />

                                <Modal
                                    title={'Change Password'}
                                    body={(
                                        <>
                                            <div className="row justify-content-center">
                                                <div className="col-lg-6 col-sm-12">
                                                    <div className="form-group">
                                                        <label htmlFor="newPassword">Enter new Password</label>
                                                        <div className="input-group">
                                                            <input
                                                                type={changePwdFormData.isNewPasswordVisible ? "text" : "password"}
                                                                className="form-control"
                                                                name="newPassword"
                                                                value={changePwdFormData.newPassword}
                                                                onChange={storeChangePwdFormData}
                                                                autoComplete="new-password" />

                                                            <Tooltip
                                                                type="btn"
                                                                className="input-group-prepend"
                                                                onClick={
                                                                    e => setChangePwdFormData({
                                                                        ...changePwdFormData,
                                                                        'isNewPasswordVisible': !changePwdFormData.isNewPasswordVisible,
                                                                    })
                                                                }
                                                                title={(changePwdFormData.isNewPasswordVisible ? 'Hide' : 'Show') + ' Password'}
                                                                tabIndex="-1"
                                                            >
                                                                <div className="input-group-text">
                                                                    <span className={"fas fa-" + (changePwdFormData.isNewPasswordVisible ? "eye-slash" : "eye")}></span>
                                                                </div>
                                                            </Tooltip>
                                                            {changePwdFormErrors.newPassword && <div className="invalid-feedback">{changePwdFormErrors.newPassword}</div>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    footer={(
                                        <>
                                            <button className="btn btn-primary" onClick={onChangePwdFormSubmitted}><i className="fas fa-check"></i>&nbsp;Change</button>
                                        </>
                                    )}
                                    show={isChangePwdPopupVisible}
                                    toggleModalVisibility={toggleChangePwdPopup} />

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UsersList;

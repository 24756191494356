import React, { useEffect, useRef, useState } from "react";
import { RequestBackendAPI } from "../../../helpers/http";

import { Link, useOutletContext ,useParams } from "react-router-dom";
import { debugPrint, toast, togglePreloader } from "../../../helpers/viewHelper";
import Pagination from "../../../widgets/Pagination";
import Table from "../../../widgets/Table";
import { confirmDialog } from "../../../helpers/viewHelper";
import Tooltip from "../../../widgets/Tooltip";

function ManufactureOrderList() {
    const { setPageTitle } = useOutletContext();
    const columns = [
        {
            'column': 'order_no',
            'label': 'Order #',
        },
        {
            'column': 'source_location',
            'label': 'Source',
        },
        {
            'column': 'destination_location',
            'label': 'Destination',
        },
        {
            'column': 'product',
            'label': 'Product',
        },
        {
            'column': 'status',
            'label': 'Status',
        },
    ];

    const tableRef = useRef(null);

    useEffect(() => {
        setPageTitle('Manufacture Orders');
    }, []);
    

    const handleOrderDelete = (order) => {
        let id = order.id;
        RequestBackendAPI({
            path: 'manufactureOrder.delete',
            pathReplaces: {
                'id': id,
            },
            method:'POST',
            callback:(success,response) => {
                if(success){

                    if (tableRef.current) {
                        tableRef.current.refreshTable();
                    }

                    toast('success', response.message ?? 'Order Delete successfully.');
                }else{
                    let errorMessage = 'Unable to delete the Order.';

                    if (response) {
                        if (response.data && response.data.message) {
                            errorMessage = response.data.message.replace('\n', '<br>');
                        } else if (response.message) {
                            errorMessage = response.message;
                        }
                    }

                    toast('error', errorMessage);
                }
            }
        })
    }

    const orderDelateConfirmation = (e, order) => {
        e.preventDefault();

        e.target.blur();

        confirmDialog({
            message:'Are you sure you want to delete this order',
            confirmBtn:{
                onClick:() => {
                    handleOrderDelete(order)
                }
            },
            cancelBtn:{
                onClick:() => {

                }
            }
        })
    }

    const handleOrderComplete = (order) => {
        let id = order.id;
        RequestBackendAPI({
            path: 'manufactureOrder.status.complete',
            pathReplaces: {
                'id': id,
            },
            method:'POST',
            callback:(success,response) => {
                debugPrint(response);
                if(success){
                    if (tableRef.current) {
                        tableRef.current.refreshTable();
                    }

                    toast('success', response && response.message ? response.message : 'Order Completed successfully.');
                }else{
                    let errorMessage = 'Unable to complete the Order.';

                    if(response){
                        if (response.data && response.data.message) {
                            errorMessage = response.data.message.replace('\n', '<br>');
                        } else if (response.message) {
                            errorMessage = response.message;
                        }
                    }

                    toast('error', errorMessage);
                }
            }
        })
    }

    const orderCompleteConfirmation = (e, order) => {
        e.preventDefault();

        e.target.blur();
        debugPrint(order)
        confirmDialog({
            message:'Are you sure you want to complete this order',
            confirmBtn:{
                onClick:() => {
                    handleOrderComplete(order)
                }
            },
            cancelBtn:{
                onClick:() => {

                }
            }
        })
    }

    const cancelOrder = (order) => {
        try {
            togglePreloader('show');

            RequestBackendAPI({
                path: 'manufactureOrder.status.cancel',
                pathReplaces: {
                    'id': order.id,
                },
                method:'POST',
                callback:(success, response) => {
                    let message = response && response.message ? response.message : '';

                    if (success) {
                        if (!message) {
                            message = 'The Order has been cancelled.';
                        }

                        if (tableRef.current) {
                            tableRef.current.refreshTable();
                        }
                    } else {
                        if (!message) {
                            message = 'Unable to cancel the Order.';
                        }
                    }

                    toast((success ? 'success' : 'error'), message);

                    togglePreloader('hide');
                }
            });
        } catch(err) {
            debugPrint('[cancelOrder] Error occurred:');

            toast('error', 'Some error occurred when trying to cancel the order.');

            togglePreloader('hide');
        }
    };

    const onCancelOrderClicked = (e, order) => {
        e.preventDefault();

        document.activeElement.blur();

        confirmDialog({
            message: 'Are you sure you want to cancel this order?',
            confirmBtn: {
                onClick: () => {
                    cancelOrder(order);
                },
            },
            cancelBtn: {
                onClick: () => {},
            },
        });
    };

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-4 mb-3">
                                    <Link to={'/orders/manufacture-orders/add'} className="btn btn-primary">
                                        <i className="fas fa-plus-circle"></i> Add Manufacture Order
                                    </Link>
                                </div>
                            </div>

                            <Table
                                apiPath='manufactureOrder.list'
                                reqMethod='POST'
                                ref={tableRef}
                                dataKeyFromResponse='manufactureOrder'
                                columns={columns}
                                actionColumnFn={(rowData) => {
                                    return (
                                        <>
                                            {
                                                rowData.status == "draft" && 
                                                <>
                                                    <Tooltip type="route" className="text-primary" to={`/orders/manufacture-orders/edit/${rowData.id}`} title="Edit">
                                                        <i className="fas fa-pencil"></i>
                                                    </Tooltip>
                                                    &nbsp;|&nbsp;
                                                    <Tooltip type="btn" className="text-success" href="javascript:void(0);" onClick={(e) => orderCompleteConfirmation(e, rowData)} title="Confirm">
                                                        <i className="fas fa-check-double"></i>
                                                    </Tooltip>
                                                    &nbsp;|&nbsp;
                                                    <Tooltip type="btn" className="text-warning" href="javascript:void(0);" onClick={(e) => onCancelOrderClicked(e, rowData)} title="Cancel">
                                                        <i className="fas fa-ban"></i>
                                                    </Tooltip>
                                                    &nbsp;|&nbsp;
                                                    <Tooltip type="btn" className="text-danger" href="javascript:void(0);" onClick={(e) => orderDelateConfirmation(e, rowData)} title="Delete">
                                                        <i className="fas fa-trash"></i>
                                                    </Tooltip>
                                                </>
                                            }
                                            {
                                                (rowData.status == "completed" || rowData.status == "canceled") &&
                                                <>
                                                    <Tooltip type="route" className="text-primary" to={`/orders/manufacture-orders/edit/${rowData.id}`} title="View">
                                                        <i className="fas fa-eye"></i>
                                                    </Tooltip>

                                                </>
                                            }
                                        </>
                                    );
                                }} />

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ManufactureOrderList;

import { useEffect, useRef, useState } from "react";
import $ from 'jquery';
import DivWithPreLoader from "../../widgets/DivWithPreLoader";
import { RequestBackendAPI } from "../../helpers/http";
import { debugPrint, setWindowTitle, toast } from "../../helpers/viewHelper";
import { useOutletContext } from "react-router-dom";
import SelectField from "../../widgets/Fields/SelectField";
import CustomInputField from "../../widgets/Fields/CustomInputField";
import Tooltip from "../../widgets/Tooltip";

export default function BillingScreenLayout2() {
    const originalWindowTitle = 'Bill an Order';

    const initialOrderData = {
        'orderId': null,
        'orderNo': null,
        'products': [],
        'customer_mobile_no': '',
        'payment_mode': null,
        'calculations': [],
        'currencySymbol': '&#8377;',
        'currencySymbolPosition': 'before',
    };

    const initialProductData = {
        'line_id': null,
        'product_id': null,
        'name': null,
        'qty': null,
        'unit_price': null,
        'stock_count': null,
    };

    const [ isLoading, toggleProgressBar ] = useState(false);

    const [ orderData, setOrderData ] = useState({...initialOrderData});

    const [ orderFormErrors, setOrderFormErrors ] = useState({...initialOrderData});

    const [ ddPaymentModes, setDdPaymentModes ] = useState({});

    const onOrderFieldChanged = (e, fieldName) => {
        let value = e.target.value,
            newOrderData = {...orderData};

        if (typeof(fieldName) === 'object') {
            let productInfo = {
                ...newOrderData.products[fieldName.productIndex],
                [fieldName.fieldName]: value,
            };

            newOrderData.products[fieldName.productIndex] = {
                ...productInfo,
            };

            calculatePrices({
                'productIndex': fieldName.productIndex,
                'productId': productInfo.product_id,
                'qty': productInfo.qty,
            });
        } else {
            newOrderData[fieldName] = value;
        }

        setOrderData({...newOrderData});
    };

    const addNewProduct = () => {
        setOrderData(prevOrderData => {
            return {
                ...prevOrderData,
                'products': [
                    ...prevOrderData.products,
                    {...initialProductData},
                ]
            };
        });
    };

    const validateAndGetFormData = (showValidationErr = true, delProductIndex = -1) => {
        let validatedProducts = [],
            newFormErrors = {},
            isValid = true;

        for (let index = 0; index < orderData.products.length; index++) {
            let product = orderData.products[index];

            if (delProductIndex === index) {
                continue;
            }

            let isProductInvalid = true, isQtyInvalid = true;

            if (product.product_id && !isNaN(Number(product.product_id))) {
                isProductInvalid = false;
            }

            if (product.qty && !isNaN(Number(product.qty))) {
                isQtyInvalid = false;
            }

            if (showValidationErr) {
                if (isProductInvalid || isQtyInvalid) {
                    if (typeof(newFormErrors.products) == 'undefined') {
                        newFormErrors.products = new Array(orderData.products.length).fill({
                            'product_id': '',
                            'qty': '',
                        });
                    }
                }

                if (isProductInvalid) {
                    newFormErrors.products[index].product_id = 'Invalid Product';
                }

                if (isQtyInvalid) {
                    newFormErrors.products[index].qty = 'Invalid Quantity';
                }
            }

            if (isProductInvalid || isQtyInvalid) {
                if ((orderData.products.length - 1) !== index) {
                    isValid = false;
                }
            } else {
                validatedProducts.push(product);
            }
        }

        if (showValidationErr) {
            if (!orderData.payment_mode) {
                newFormErrors.payment_mode = 'Invalid Payment mode.';
                isValid = false;
            }
        }

        setOrderFormErrors({...newFormErrors});

        return {
            'isValid': isValid,
            'products': validatedProducts,
        }
    }

    const calculatePrices = (reqData) => {
        try {
            if (!reqData) {
                return;
            }

            toggleProgressBar(true);

            if (typeof(reqData.productId) !== 'undefined') {
                if (!(reqData.productId && reqData.qty)) {
                    toggleProgressBar(false);
                    return;
                }
            }

            let validation = validateAndGetFormData(false, reqData['delProductIndex'] ?? -1);

            RequestBackendAPI({
                path: 'billing.calcPrice.by-productId',
                method: 'POST',
                data: {
                    ...reqData,
                    'products': validation.products,
                },
                callback: (success, response) => {
                    let productInfo = {},
                        calculations = [],
                        deleteProduct = false;

                    if (success) {
                        if (response.data) {
                            if (response.data.product) {
                                productInfo = {
                                    ...response.data.product,
                                };
                            }

                            if (response.data.calculations) {
                                calculations = [
                                    ...response.data.calculations,
                                ];
                            }
                        }

                        deleteProduct = reqData.action === 'delete';
                    } else {
                        let message = 'Unable to get the product details.';

                        if (response.data && response.data.message) {
                            message = response.data.message.replace('\n', '<br>');
                        } else if (response.message) {
                            message = response.message;
                        }

                        toast('error', message);
                    }

                    if (deleteProduct) {
                        setOrderData(prevOrderData => {
                            let updatedProductsData = [...prevOrderData.products];

                            updatedProductsData.splice(reqData['delProductIndex'], 1);

                            return {
                                ...prevOrderData,
                                'products': [
                                    ...updatedProductsData,
                                ],
                                'calculations': calculations,
                            };
                        });
                    } else {
                        setOrderData(prevOrderData => {
                            let updatedProductsData = [...prevOrderData.products];

                            if (typeof(reqData.productIndex) !== 'undefined') {
                                updatedProductsData[reqData.productIndex] = {
                                    ...updatedProductsData[reqData.productIndex],
                                    ...productInfo,
                                };
                            }

                            return {
                                ...prevOrderData,
                                'products': [
                                    ...updatedProductsData,
                                ],
                                'calculations': calculations,
                            };
                        });
                    }

                    toggleProgressBar(false);
                },
            });
        } catch(err) {
            debugPrint('[calculatePrices] error occurred:', err);

            toggleProgressBar(false);
        }
    };

    const onBtnDeleteProductClicked = (e, productIndex) => {
        e.preventDefault();

        document.activeElement.blur();

        calculatePrices({
            'action': 'delete',
            'delProductIndex': productIndex,
        });
    };

    const onBtnPrintClicked = (e) => {
        e.preventDefault();

        document.activeElement.blur();

        let defaultAjaxSuccessMessage = 'The order has been created successfully.',
            defaultAjaxFailedMessage = 'Unable to crate an Order',
            defaultErrMessage = 'Error occurred when trying to Create an Order.';

        try {
            // document.activeElement.blur();

            toggleProgressBar(true);

            let validation = validateAndGetFormData(),
                ajaxURL = 'billing.createOrder',
                ajaxPathParams = {};

            if (!validation['isValid']) {
                toggleProgressBar(false);
                toast('error', 'There are some invalid fields found. Please correct them all to proceed.');

                return;
            }

            if (orderData.id) {
                ajaxURL = 'billing.editOrder';
                ajaxPathParams = {
                    'id': orderData.id,
                };
                defaultAjaxSuccessMessage = 'The order has been updated successfully.';
                defaultAjaxFailedMessage = 'Unable to update the Order';
                defaultErrMessage = 'Error occurred when trying to update the Order.';
            }

            RequestBackendAPI({
                path: ajaxURL,
                pathReplaces: ajaxPathParams,
                method: 'POST',
                data: {
                    ...orderData,
                    'products': validation.products,
                },
                callback: (success, response) => {
                    let message, responseData;

                    if (response) {
                        if (response.message) {
                            message = response.message;
                        }

                        if (response.data) {
                            responseData = response.data;

                            if (response.data.message) {
                                message = response.data.message.replace('\n', '<br>');
                            }
                        }
                    }

                    if (success) {
                        if (orderData.id) {
                            if (responseData && responseData.orderData) {
                                setOrderData(prevOrderData => {
                                    return {
                                        ...prevOrderData,
                                        ...responseData.orderData,
                                    };
                                });

                                addNewProduct();
                            }
                        } else {
                            setOrderData({
                                ...initialOrderData,
                            });
                        }

                        if (responseData && responseData.printContentHTML) {
                            $('.portal-sale-order-receipt').remove();
                            $('body').append(responseData.printContentHTML);
                        }

                        toast('success', message ?? defaultAjaxSuccessMessage);

                        const totalImagesInPrint = $('.portal-sale-order-receipt img').length;
                        let imagesLoaded = 0;

                        if (totalImagesInPrint == 0) {
                            window.print();
                        } else {
                            $('.portal-sale-order-receipt img').on('load', () => {
                                imagesLoaded++;

                                if (totalImagesInPrint == imagesLoaded) {
                                    window.print();
                                }
                            }).on('error', () => {
                                imagesLoaded++;

                                if (totalImagesInPrint == imagesLoaded) {
                                    window.print();
                                }
                            });
                        }
                    } else {
                        if (responseData && responseData.validationErrors) {
                            setOrderFormErrors(prevErrors => {
                                return {
                                    ...prevErrors,
                                    ...responseData.validationErrors,
                                };
                            });
                        }

                        toast('error', message ?? defaultAjaxFailedMessage);
                    }

                    toggleProgressBar(false);
                },
            });
        } catch(err) {
            debugPrint('[onBtnPrintClicked] error occurred:', err);

            toggleProgressBar(false);

            toast('error', defaultErrMessage);
        }
    };

    const onBeforePrint = () => {
        toggleProgressBar(true);

        let orderNo = $('.portal-sale-order-receipt .portal-sale-order-receipt-order-name').text();

        if (orderNo && orderNo.trim()) {
            setWindowTitle('Order #' + orderNo.trim());
        }
    };

    const onAfterPrint = () => {
        if (originalWindowTitle != document.title.trim()) {
            setWindowTitle(originalWindowTitle);
        }

        toggleProgressBar(false);

        $('.portal-sale-order-receipt').remove();
    };

    const getDropDowns = () => {
        try {
            RequestBackendAPI({
                path: 'billing.dropDowns',
                method: 'POST',
                callback: (success, response) => {
                    let errMessage = 'Unable to get Payment modes from Server.',
                        newPaymentModes = {};

                    if (success) {
                        if (response && response.data) {
                            if (response.data.paymentModes && !Array.isArray(response.data.paymentModes)) {
                                newPaymentModes = response.data.paymentModes;
                            }
                        }
                    } else {
                        if (response && response.message) {
                            errMessage = response.message;
                        }

                        toast('error', errMessage);
                    }

                    setDdPaymentModes({...newPaymentModes});
                },
            });
        } catch(err) {
            debugPrint('[getDropDowns] Error occurred:', err);
        }
    };

    const isFieldInvalid = (fieldName) => {
        if (orderFormErrors && typeof(orderFormErrors[fieldName]) != 'undefined') {
            return orderFormErrors[fieldName];
        }

        return false;
    };

    useEffect(()=> {
        if (orderData.products.length === 0) {
            setOrderData({
                ...orderData,
                'products': [
                    ...orderData.products,
                    {...initialProductData},
                ],
            })
        }
    }, [orderData]);

    useEffect(()=> {
        setWindowTitle(originalWindowTitle);

        getDropDowns();

        window.addEventListener('beforeprint', onBeforePrint);

        window.addEventListener('afterprint', onAfterPrint);

        return () => {
            window.removeEventListener('beforeprint', onBeforePrint);

            window.removeEventListener('afterprint', onAfterPrint);
        };
    }, []);

    return (
        <>

            <div className="row mt16 o_portal_sale_sidebar">
                <div className="col-12 col-lg justify-content-end">

                    <DivWithPreLoader isLoading={isLoading}>
                        <div className="card pb-5" id="portal_sale_content">
                            <div className="card-body">
                                <div>
                                    <div className="table-responsive calcBy-productId" id="new_sale_order_table_parent">
                                        <table className="table table-sm table-sticky-header" id="new_sale_order_table">
                                            <thead className="bg-100">
                                                <tr>
                                                    <th className="text-left" style={{width: 'auto'}}>
                                                        <span>Product</span>&nbsp;&nbsp;
                                                        <Tooltip type="btn" className="text-primary" title="Add" tabIndex="-1" onClick={e => {
                                                            e.preventDefault();

                                                            document.activeElement.blur();

                                                            addNewProduct();
                                                        }}>
                                                            <i className="fa fa-plus-circle"></i>
                                                        </Tooltip>
                                                    </th>
                                                    <th className="text-center" style={{width: "20%"}}>Quantity</th>
                                                    <th className="text-right" style={{width: "20%"}}>Amount</th>
                                                    <th className="text-right" style={{width: "10%", whiteSpace: 'nowrap'}}></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    orderData.products && orderData.products.length > 0 &&
                                                    orderData.products.map((product, productIndex) => {
                                                        let prodErrors = {};

                                                        if (orderFormErrors && orderFormErrors.products && typeof(orderFormErrors.products[productIndex]) != 'undefined') {
                                                            prodErrors = orderFormErrors.products[productIndex];
                                                        }

                                                        return (
                                                            <BillTableRow 
                                                                product={product}
                                                                productIndex={productIndex}
                                                                currencySymbol={orderData.currencySymbol}
                                                                currencySymbolPosition={orderData.currencySymbolPosition}
                                                                addProductFn={addNewProduct}
                                                                onChangeProductFn={onOrderFieldChanged}
                                                                deleteProductFn={onBtnDeleteProductClicked}
                                                                errors={prodErrors}
                                                            />
                                                        );
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>

                                    {
                                        orderData.calculations &&
                                        <div className="row" style={{pageBreakInside: "avoid"}} id="new_sale_order_totals_table_parent">
                                            <div className="col-sm-7 col-md-6 col-lg-4 ml-auto">
                                                <BillCalculationsTable
                                                    calculations={orderData.calculations}
                                                    currencySymbol={orderData.currencySymbol}
                                                    currencySymbolPosition={orderData.currencySymbolPosition}
                                                />
                                            </div>
                                        </div>
                                    }
                                </div>

                                <div className="row justify-content-center mt-3">
                                    <div className="col-sm-12 col-md-6 col-lg-3">
                                        {
                                            ddPaymentModes && Object.keys(ddPaymentModes).length > 0 &&
                                            <>
                                                <div className="form-group mb-0 is-required-field payment_mode_field_parent">
                                                    <label htmlFor="paymentMode">Payment Mode</label>
                                                    <SelectField
                                                        name="paymentMode"
                                                        value={orderData.payment_mode}
                                                        options={ddPaymentModes}
                                                        onChangeFn={e => onOrderFieldChanged(e, 'payment_mode')}
                                                        className={(isFieldInvalid('payment_mode') ? 'is-invalid' : '')} />
                                                </div>
                                                {
                                                    isFieldInvalid('payment_mode') &&
                                                    <div className="invalid-feedback">{isFieldInvalid('payment_mode')}</div>
                                                }
                                            </>
                                        }
                                    </div>

                                    <div className="col-sm-12 col-md-6 col-lg-3 sm-mt-3">
                                        <div className={"form-group mb-0 parent-custMobileNo " + (isFieldInvalid('customer_mobile_no') ? 'is-invalid' : '')}>
                                            <label className="" htmlFor="custMobileNo"><strong>Customer contact</strong></label>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="ic-text">+91</span>
                                                </div>

                                                <CustomInputField
                                                    name="custMobileNo"
                                                    value={orderData.customer_mobile_no}
                                                    fieldType="phone"
                                                    onChangeFn={e => onOrderFieldChanged(e, 'customer_mobile_no')}
                                                    className={
                                                        (isFieldInvalid('customer_mobile_no') ? 'is-invalid' : '')
                                                    } />
                                                {
                                                    isFieldInvalid('customer_mobile_no') &&
                                                    <div className="invalid-feedback">{isFieldInvalid('customer_mobile_no')}</div>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-12 col-md-6 col-lg-3 sm-text-center">
                                        <label className="d-block">&nbsp;</label>
                                        <button className="btn btn-info" id="btnPrint" onClick={onBtnPrintClicked}>
                                            <i className="fa fa-print"></i>&#160;
                                            {
                                                orderData.orderId ? 'Confirm and Print' : 'Save and Print'
                                            }
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DivWithPreLoader>

                </div>
            </div>

        </>
    );
}

function BillTableRow({productIndex, product, currencySymbol, currencySymbolPosition, onChangeProductFn, addProductFn, deleteProductFn, errors = {}}) {
    const getProductsForDD = (inputValue, dropDownCallback) => {
        try {
            RequestBackendAPI({
                path: 'billing.productSearch',
                method: 'POST',
                data: {
                    'productSearch': inputValue,
                },
                callback: (success, response) => {
                    let products = [];

                    if (success) {
                        if (response && response.data && response.data.products) {
                            products = [...response.data.products];
                        }
                    } else {
                        toast('error', response && response.message ? response.message : 'Unable to get the Products.');
                    }

                    dropDownCallback([...products]);
                },
            });
        } catch(err) {
            debugPrint('[BillTableRow -> getProductsForDD()] Error occurred:', err);

            dropDownCallback([]);
        }
    };

    return (
        <tr data-product-index={productIndex}>
            <td className="text-left">
                <SelectField
                    isServerSide={true}
                    ajaxDataFetchFn={getProductsForDD}
                    name="product_id"
                    value={product.product_id}
                    onChangeFn={e => onChangeProductFn(e, {
                        'productIndex': productIndex,
                        'fieldName': 'product_id',
                    })}
                    className={
                        (errors && typeof(errors['product_id']) != 'undefined') ? "is-invalid" : ""
                    }
                    placeholder=""
                    noDataPlaceholder={(searchText) => {
                        return searchText ? `There are no products matching "${searchText}"` : 'Type product code or name to search';
                    }} />
                {
                    (errors && typeof(errors['product_id']) != 'undefined') &&
                    <div className="invalid-feedback text-left">{errors['product_id']}</div>
                }
            </td>
            <td className="text-center">
                <CustomInputField
                    name="qty"
                    value={product.qty}
                    fieldType="number"
                    onChangeFn={e => onChangeProductFn(e, {
                        'productIndex': productIndex,
                        'fieldName': 'qty',
                    })}
                    className={
                        (errors && typeof(errors['qty']) != 'undefined') ? "is-invalid" : ""
                    } />
                {
                    (errors && typeof(errors['qty']) != 'undefined') &&
                    <div className="invalid-feedback text-left">{errors['qty']}</div>
                }
            </td>
            <td className="text-right valign-center">
                { currencySymbolPosition === 'before' && (
                    <span dangerouslySetInnerHTML={{__html: currencySymbol}} />
                ) }
                <span className="content">
                    { (product.unit_price ?? '0.00') * product.qty }
                </span>
                { currencySymbolPosition === 'after' && (
                    <span dangerouslySetInnerHTML={{__html: currencySymbol}} />
                ) }
            </td>
            <td className="text-center valign-center">
                <Tooltip type="btn" className="text-primary" title="Add" tabIndex="-1" onClick={e => {
                    e.preventDefault();

                    document.activeElement.blur();

                    addProductFn();
                }}>
                    <i className="fa fa-plus-circle"></i>
                </Tooltip>
                &nbsp;|&nbsp;
                <Tooltip type="btn" className="text-danger" title="Delete" tabIndex="-1" onClick={e => deleteProductFn(e, productIndex)}>
                    <i className="fa fa-trash"></i>
                </Tooltip>
            </td>
        </tr>
    );
}

function BillCalculationsTable({calculations, currencySymbol, currencySymbolPosition}) {
    return (
        <table className="table table-sm">
            <tbody>
                {
                    calculations.map((calc) => {
                        return (
                            <tr>
                                {
                                    calc.bold ?
                                    <th className="text-left">{calc.name}</th>
                                    :
                                    <td className="text-left">{calc.name}</td>
                                }
                                <td className="text-right">
                                    { currencySymbolPosition === 'before' && (
                                        <span dangerouslySetInnerHTML={{__html: currencySymbol}} />
                                    ) }
                                    {calc.amount}
                                    { currencySymbolPosition === 'after' && (
                                        <span dangerouslySetInnerHTML={{__html: currencySymbol}} />
                                    ) }
                                </td>
                            </tr>
                        );
                    })
                }
            </tbody>
        </table>
    );
}

import React, { useEffect, useRef, useState } from "react";
import { RequestBackendAPI } from "../../../helpers/http";

import { Link, useOutletContext ,useParams } from "react-router-dom";
import { debugPrint, toast } from "../../../helpers/viewHelper";
import Pagination from "../../../widgets/Pagination";
import Table from "../../../widgets/Table";
import { confirmDialog } from "../../../helpers/viewHelper";
import Tooltip from "../../../widgets/Tooltip";

function CustomersList() {
    const { setPageTitle } = useOutletContext();
    const columns = [
        {
            'column': 'name',
            'label': 'Name',
        },
        {
            'column': 'email',
            'label': 'Email',
        },
        {
            'column': 'phone',
            'label': 'Phone',
        },
        {
            'column': 'state_id',
            'label': 'State',
        },
        {
            'column': 'country_id',
            'label': 'Country',
        },
    ];

    const tableRef = useRef(null);

    useEffect(() => {
        setPageTitle('Customers');
    }, []);
    

    const handleCustomerDelete = (customer) => {
        let id = customer.id;
        RequestBackendAPI({
            path: `customerMaster.delete`,
            pathReplaces: {
                'id': id,
            },
            method:'POST',
            callback:(success, response) => {
                debugPrint('customer delete:', response);
                if(success){

                    if (tableRef.current) {
                        tableRef.current.refreshTable();
                    }

                    toast('success', response.message ?? 'Customer Delete successfully.');
                } else {
                    let message = 'Unable to delete the Customer.';

                    if (response) {
                        if (response.data && response.data.message) {
                            message = response.data.message.replace('\n', '<br>');
                        } else if (response.message) {
                            message = response.message;
                        }
                    }

                    toast('error', message);
                }
            }
        })
    }

    const customerDelateConfirmation = (e, customer) => {
        e.preventDefault();

        e.target.blur();

        confirmDialog({
            message:'Are you sure you want to delete this customer',
            confirmBtn:{
                onClick:() => {
                    handleCustomerDelete(customer)
                }
            },
            cancelBtn:{
                onClick:() => {

                }
            }
        })
    }

return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-4 mb-3">
                                    <Link to={'/admin/customers/add'} className="btn btn-primary">
                                        <i className="fas fa-plus-circle"></i> Add Customer
                                    </Link>
                                </div>
                            </div>

                            <Table
                                apiPath='customerMaster.list'
                                reqMethod='POST'
                                ref={tableRef}
                                dataKeyFromResponse='customers'
                                columns={columns}
                                actionColumnFn={(rowData) => {
                                    return (
                                        <>
                                            <Tooltip type="route" className="text-primary" to={`/admin/customers/edit/${rowData.id}`} title="Edit Custoemr">
                                                <i className="fas fa-pencil"></i>
                                            </Tooltip>
                                            &nbsp;|&nbsp;
                                            <Tooltip type="btn" className="text-danger" onClick={(e) => customerDelateConfirmation(e, rowData)} title="Delete Customer">
                                                <i className="fas fa-trash"></i>
                                            </Tooltip>
                                        </>
                                    );
                                }} />

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CustomersList;

import axios from "axios";
import { LocalDB } from "./localDB";
import Constants from "../Constants";
import { debugPrint } from "./viewHelper";

function checkAndRefreshAuthToken(response) {
    if (response && response.headers && response.headers['x-new-auth-token']) {
        let user = LocalDB.getJSON('user');

        LocalDB.storeJSON('user', {
            ...user,
            'authToken': response.headers['x-new-auth-token'],
        });
    }
}

async function Http({ url, method, headers, data, callback, responseType }) {
    let axiosOptions = {
        url: url,
        headers: {
            // 'X-USER-TIMEZONE': Intl.DateTimeFormat().resolvedOptions().timeZone,
            ...(headers ?? {}),
        },
    };

    method = method || 'get';

    axiosOptions['method'] = method.toUpperCase();

    if (method.toLowerCase() === 'get') {
        axiosOptions['params'] = data;
    } else {
        axiosOptions['data'] = data;
    }

    let user = LocalDB.getJSON('user');

    if (user && user.authToken) {
        if (!axiosOptions.headers) {
            axiosOptions.headers = {};
        }

        if (Constants.API_AUTH_TYPE === 'accessToken') {
            axiosOptions.headers['Authorization'] = `Bearer ${user.authToken}`;
        } else if (Constants.API_AUTH_TYPE === 'cookie') {
            axiosOptions.headers['Cookie'] = user.authToken;
        }
    }
    axiosOptions['withCredentials'] = true;
    // debugPrint('axiosOptions.headers:', axiosOptions.headers);

    if (typeof (responseType) !== 'undefined') {
        axiosOptions['responseType'] = responseType;
    }

    axios(axiosOptions)
        .then((response) => {
            // checkAndRefreshAuthToken(response);

            callback(response ?? {}, false);
        })
        .catch((err) => {
            debugPrint('[Http] Axios error:', err);
            // checkAndRefreshAuthToken(err.response);

            callback(err.response ?? {}, true, err);
        });
}

function BackendAPIBaseURL(path, pathReplaces = {}) {
    function getBackendAPIPathFromConstants(path, urls) {
        let pathURL = '';
        let pathSplit = path.split('.');

        if (pathSplit[0] in urls) {
            if (typeof(urls[pathSplit[0]]) == 'object' && pathSplit.length > 1) {
                pathURL += getBackendAPIPathFromConstants(pathSplit.slice(1).join('.'), urls[pathSplit[0]]);
            } else {
                return urls[pathSplit[0]];
            }
        } else {
            return pathURL;
        }

        return pathURL;
    }

    let apiUrl = process.env.REACT_APP_BACKEND_API_BASE_URL;
    // let apiUrl = '';

    apiUrl += (apiUrl.endsWith('/') ? '' : '/');

    if (path in Constants.BACK_END_API_ENDPOINTS) {
        path = Constants.BACK_END_API_ENDPOINTS[path];
    } else if (path.split('.')[0] in Constants.BACK_END_API_ENDPOINTS) {
        path = getBackendAPIPathFromConstants(path, Constants.BACK_END_API_ENDPOINTS);
    }

    if (pathReplaces) {
        Object.keys(pathReplaces).map(k => {
            path = path.replace(`:${k}`, pathReplaces[k]);
        });
    }

    return apiUrl + (path.startsWith('/') ? path.substr(1) : path);
}

function RequestBackendAPI({ path, pathReplaces = {}, method = 'get', data, callback, responseType, isJSON = true, returnHeadersOnSuccess = false }) {
    let url = BackendAPIBaseURL(path, pathReplaces);

    if (isJSON && Constants.REQ_BACK_END_WITH_JSON_RPC && data) {
        data = {
            'jsonrpc': '2.0',
            'params': data,
        };
    }

    if (!data && isJSON) {
        data = {};
    }

    return Http({
        url,
        method,
        headers: isJSON ? {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        } : {},
        data,
        callback: (response, isError, xhr) => {
            if (isError) {
                if (response && response.data) {
                    callback(response.data.success, response.data);
                } else {
                    callback(false, null);
                }
            } else {
                if (response && response.data) {
                    if (responseType == 'blob') {
                        let fileName;

                        if (response.headers && response.headers['content-disposition']) {
                            if (response.headers['content-disposition'].includes('filename=') && response.headers['content-disposition'].includes(';')) {
                                let cdSplit = response.headers['content-disposition'].split(';');

                                for (let cdItem in cdSplit) {
                                    let cdItemValue = cdSplit[cdItem].trim();

                                    if (cdItemValue.startsWith('filename=')) {
                                        fileName = cdItemValue.split('filename=').pop();
                                        break;
                                    }
                                }
                            }
                        }

                        callback((response.status == 200 && (response.data instanceof Blob)), response.data, fileName);
                    } else {
                        if (Constants.REQ_BACK_END_WITH_JSON_RPC && response.data.jsonrpc) {
                            if (response.data.result) {
                                if (returnHeadersOnSuccess) {
                                    callback(response.data.result.success, response.data.result, response.headers);
                                } else {
                                    callback(response.data.result.success, response.data.result);
                                }
                            } else if (response.data.error) {
                                if (response.data.error.data) {
                                    callback(false, response.data.error.data);
                                } else {
                                    callback(false, response.data.error);
                                }
                            } else {
                                callback(false, null);
                            }
                        } else {
                            if (returnHeadersOnSuccess) {
                                callback(response.data.success, response.data, response.headers);
                            } else {
                                callback(response.data.success, response.data);
                            }
                        }
                    }
                } else {
                    callback(false, null);
                }
            }
        },
        responseType
    });
}

function objectToFormData(obj, form, namespace) {
    var fd = form || new FormData();
    var formKey;

    for(var property in obj) {
        if(obj.hasOwnProperty(property)) {
            if(namespace) {
                /* if (obj[property] instanceof File) {
                    // FastAPI accepts multiple files with same name but unlike other frameworks, it does not accept files with index
                    formKey = namespace;
                } else {
                    formKey = namespace + '[' + property + ']';
                } */
               // Odoo accepts multiple files with same name like PHP: files[0], files[1], ... files[n],
                formKey = namespace + '[' + property + ']';
            } else {
                formKey = property;
            }

            // if the property is an object, but not a File,
            // use recursivity.
            if(typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
                objectToFormData(obj[property], fd, formKey);
            } else {
                // if it's a string or a File object
                fd.append(formKey, obj[property]);
            }
        }
    }

    return fd;
}

export {
    Http,
    BackendAPIBaseURL,
    RequestBackendAPI,
    objectToFormData,
};
